import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Картинка со строителем в каске */
export const TendersIcon: FC<IconsProps> = ({ fill = 'white' }) => (
  <SvgIcon width={100} height={100} noFill noStroke>
    <g clipPath="url(#clip0_316_3364)">
      <path
        d="M56.1567 63.727C54.2532 64.1495 52.059 64.387 50.0499 64.3337C42.7801 64.2001 35.9337 61.4758 31.0441 56.3199C25.9166 51.2167 23.1422 44.3428 23.2759 37.073C23.2231 36.8351 23.6461 36.4915 23.884 36.4387C24.122 36.3859 24.4655 36.8089 24.5184 37.0468C24.5698 44.0259 27.2386 50.4239 32.0753 55.342C36.9121 60.26 43.5205 63.0371 50.2617 63.0384C57.2407 62.987 63.6387 60.3182 68.5568 55.4815C73.4748 50.6447 76.2519 44.0363 76.2533 37.2951C76.2004 37.0572 76.6235 36.7136 76.8614 36.6608C77.0993 36.608 77.4429 37.031 77.4958 37.269C77.3621 44.5388 74.6378 51.3851 69.4819 56.2747C65.595 60.1336 60.9154 62.6705 56.1567 63.727Z"
        fill={fill}
      />
      <path
        d="M78.1299 37.8774C77.8919 37.9302 77.654 37.9831 77.4161 38.0359L22.4296 37.7605C21.6629 37.681 20.8435 37.3636 20.4999 36.9406C19.8656 36.3324 19.7071 35.6186 19.5486 34.9048L19.4705 28.9303C19.3915 27.4499 20.8458 26.1284 22.3262 26.0494L77.3127 26.3248C78.7931 26.2458 80.1146 27.7 80.1936 29.1804L80.2717 35.155C80.5887 36.5826 79.5575 37.5605 78.1299 37.8774ZM22.4052 27.5298C21.6914 27.6883 21.0832 28.3226 21.2417 29.0364L21.0291 34.8258C21.1347 35.3017 21.1875 35.5396 21.5311 35.9627C21.8219 36.1478 22.1126 36.3329 22.6413 36.4652L77.6278 36.7406C78.3944 36.82 79.2405 36.1329 79.2672 35.1283L79.1891 29.1538C79.2685 28.3872 78.5814 27.5411 77.5768 27.5145L22.5903 27.2391C22.6431 27.477 22.4052 27.5298 22.4052 27.5298Z"
        fill={fill}
      />
      <path
        d="M67.5805 45.4625C66.6288 45.6738 65.677 45.8851 64.6725 45.8585L35.6988 45.7998C30.4381 45.7194 25.4952 42.5725 23.1434 37.6021C23.0906 37.3642 23.0378 37.1263 23.2229 36.8355C23.408 36.5447 23.6459 36.4919 23.6459 36.4919L76.6233 36.7141C76.8612 36.6612 77.152 36.8463 77.2048 37.0843C77.2577 37.3222 77.3105 37.5601 77.3105 37.5601C75.2476 41.763 71.6254 44.5645 67.5805 45.4625ZM24.6767 37.761C26.8172 41.7797 31.1257 44.3184 35.6726 44.5573L64.6463 44.616C69.1404 44.6169 73.2913 41.9477 75.6449 37.9299L24.6767 37.761Z"
        fill={fill}
      />
      <path
        d="M23.8857 27.4507C23.4098 27.5563 23.0662 27.1333 23.0134 26.8954C23.0685 15.8981 30.6575 6.22406 41.6819 3.02754C41.9199 2.97472 42.4485 3.107 42.5014 3.34494C42.5542 3.58287 42.4219 4.11156 42.184 4.16438C31.3975 7.30808 24.3638 16.1098 24.4938 26.8163C24.3087 27.1071 24.1236 27.3979 23.8857 27.4507Z"
        fill={fill}
      />
      <path
        d="M76.8629 27.6729C76.625 27.7258 76.625 27.7258 76.8629 27.6729C76.387 27.7786 76.0435 27.3555 75.9906 27.1176C76.0985 16.3582 69.1213 7.42152 58.4418 4.29994C58.1511 4.11483 57.8603 3.92972 57.9926 3.40103C58.1777 3.11028 58.3628 2.81952 58.8915 2.95181C70.0996 6.20567 77.5261 16.0413 77.4711 27.0386C77.286 27.3293 77.1008 27.6201 76.8629 27.6729Z"
        fill={fill}
      />
      <path
        d="M58.5184 19.2626C58.0425 19.3683 57.6989 18.9452 57.6461 18.7073L57.8077 3.69176C57.8872 2.92514 57.5436 2.5021 57.1472 1.84112C56.8036 1.41808 55.9842 1.10068 55.5083 1.20633L44.9869 1.0456C44.2203 0.966139 43.7972 1.30972 43.3742 1.6533C42.9511 1.99688 42.6337 2.81633 42.7394 3.2922L42.5778 18.3077C42.6306 18.5457 42.2075 18.8892 41.9696 18.9421C41.7317 18.9949 41.3881 18.5718 41.3353 18.3339L41.4969 3.31838C41.5235 2.31383 41.8409 1.49438 42.6342 0.569282C43.2424 -0.0650557 44.1413 -0.514284 44.9079 -0.434823L55.4293 -0.274092C56.4339 -0.247455 57.2533 0.0699394 57.9405 0.916024C58.8128 1.47135 59.262 2.37026 59.2353 3.37482L59.0737 18.3903C59.1794 18.8662 58.9942 19.157 58.5184 19.2626Z"
        fill={fill}
      />
      <path
        d="M63.381 27.67C62.8523 27.5377 62.5616 27.3526 62.5087 27.1146C62.9864 18.0208 65.2082 12.2847 68.5397 9.2981C68.7248 9.00734 69.2535 9.13963 69.5442 9.32474C69.835 9.50985 69.7027 10.0385 69.5176 10.3293C66.3712 13.0251 64.6781 18.8935 64.042 27.2736C63.8041 27.3264 63.6189 27.6172 63.381 27.67Z"
        fill={fill}
      />
      <path
        d="M37.3679 27.4538C36.892 27.5594 36.5484 27.1364 36.4956 26.8985C36.0744 18.2538 34.0928 12.7019 31.2119 9.84627C30.9211 9.66116 30.8154 9.1853 31.2385 8.84172C31.4236 8.55096 31.8995 8.44531 32.243 8.86835C35.6527 11.8563 37.502 17.9369 37.976 26.8194C37.7909 27.1102 37.6058 27.401 37.3679 27.4538Z"
        fill={fill}
      />
      <path
        d="M56.8167 67.825C54.6753 68.3004 52.2432 68.5907 49.9961 68.5903C42.2504 68.5623 34.8754 65.7057 29.2192 60.4703C28.9284 60.2852 28.8228 59.8093 29.2458 59.4657C29.4309 59.175 29.9068 59.0693 30.2504 59.4924C35.6158 64.5427 42.7001 67.2141 50.2079 67.295C57.7156 67.3758 64.8274 64.5486 70.2213 59.6062C70.4065 59.3154 70.9351 59.4477 71.2259 59.6328C71.5167 59.8179 71.3844 60.3466 71.1993 60.6373C66.9688 64.0732 62.0512 66.6629 56.8167 67.825Z"
        fill={fill}
      />
      <path
        d="M56.7894 73.3235C54.648 73.799 52.2158 74.0893 49.9688 74.0888C42.2231 74.0608 34.848 71.2043 29.1918 65.9688C28.9011 65.7837 28.7954 65.3079 29.2185 64.9643C29.4036 64.6735 29.8794 64.5679 30.223 64.9909C35.5885 70.0412 42.6728 72.7127 50.1805 72.7935C57.6883 72.8743 64.5622 70.0999 70.194 65.1047C70.3791 64.8139 70.9078 64.9462 71.1986 65.1313C71.4893 65.3164 71.357 65.8451 71.1719 66.1359C66.7036 69.6245 62.0239 72.1614 56.7894 73.3235Z"
        fill={fill}
      />
      <path
        d="M61.4651 88.7633C57.6582 89.6085 53.7456 89.9778 49.7802 90.1092C42.8011 90.1607 35.7431 88.7317 29.3726 85.9018C25.2225 84.0769 21.2048 81.7233 17.61 79.0261C17.3193 78.841 17.2136 78.3651 17.3987 78.0744C17.5838 77.7836 18.0597 77.678 18.3505 77.8631C21.9452 80.5603 25.725 82.9667 29.8223 84.5537C36.1399 87.1456 42.7221 88.6802 49.7012 88.6288C56.6803 88.5773 63.3952 87.3362 69.5553 84.7203C73.6796 83.0556 77.4076 80.73 81.0298 77.9285C81.2149 77.6378 81.7436 77.7701 82.0344 77.9552C82.3252 78.1403 82.1929 78.669 82.0078 78.9597C78.3855 81.7612 74.4196 84.1396 70.0574 85.8571C67.5986 87.152 64.5583 88.0766 61.4651 88.7633Z"
        fill={fill}
      />
      <path
        d="M53.7438 98.9657L2.53762 98.8497C1.771 98.7702 1.24231 98.6379 0.607971 98.0298C0.211566 97.3688 -0.184838 96.7078 0.185381 96.1263L2.35567 83.4111C3.1232 78.9965 5.52962 75.2166 9.25755 72.891L29.4837 59.4128C29.6688 59.122 30.1975 59.2543 30.2503 59.4923C30.5411 59.6774 30.4088 60.2061 30.2237 60.4968L9.7596 74.0279C6.50754 76.2478 4.0483 79.7897 3.41306 83.6757L1.48069 96.3381C1.29559 96.6288 1.63917 97.0518 1.69199 97.2898C1.98275 97.4749 2.2735 97.66 2.51144 97.6072L53.7176 97.7232C53.9556 97.6704 54.2992 98.0934 54.352 98.3314C54.4576 98.8072 54.2197 98.8601 53.7438 98.9657Z"
        fill={fill}
      />
      <path
        d="M97.7331 99.1861C97.4952 99.2389 97.4952 99.2389 97.2572 99.2917L46.051 99.1757C45.8131 99.2285 45.4695 98.8055 45.4167 98.5675C45.3639 98.3296 45.7869 97.986 46.0248 97.9332L97.231 98.0492C97.469 97.9964 97.9448 97.8908 98.13 97.6C98.3151 97.3093 98.5002 97.0185 98.4474 96.7806L96.5994 83.9588C95.9922 80.0991 93.6666 76.3711 90.4682 74.3349L70.2475 60.8486C70.2475 60.8486 70.2475 60.8486 70.1947 60.6107C69.9039 60.4255 70.0362 59.8969 70.2213 59.6061C70.4064 59.3153 70.9351 59.4476 70.988 59.6856L91.2087 73.1719C94.7506 75.6311 97.3669 79.5442 97.7891 83.6947L99.637 96.5164C99.7955 97.2302 99.4781 98.0497 99.055 98.3933C98.8699 98.684 98.209 99.0804 97.7331 99.1861Z"
        fill={fill}
      />
      <path
        d="M29.8484 85.796C29.3725 85.9016 29.0289 85.4786 28.9761 85.2407L28.8226 59.8093C28.7698 59.5714 29.1928 59.2278 29.4308 59.175C29.6687 59.1222 30.0123 59.5452 30.0651 59.7832L30.4565 85.1616C30.2714 85.4524 30.0863 85.7432 29.8484 85.796Z"
        fill={fill}
      />
      <path
        d="M18.3237 78.8674C17.8478 78.9731 17.5042 78.5501 17.4514 78.3121L17.7444 67.262C17.6916 67.0241 18.1146 66.6805 18.3525 66.6277C18.5905 66.5748 18.9341 66.9979 18.9869 67.2358L18.6939 78.2859C18.7467 78.5239 18.5616 78.8146 18.3237 78.8674Z"
        fill={fill}
      />
      <path
        d="M15.7823 98.9054C15.2536 98.7731 14.9628 98.588 15.0951 98.0593L17.637 78.0215C17.5842 77.7836 18.0072 77.44 18.2452 77.3871C18.4831 77.3343 18.8267 77.7574 18.8795 77.9953L16.3904 98.2711C16.2053 98.5618 16.0202 98.8526 15.7823 98.9054Z"
        fill={fill}
      />
      <path
        d="M70.3483 86.0419C70.1104 86.0948 70.1104 86.0948 70.3483 86.0419C69.8724 86.1476 69.5288 85.7245 69.476 85.4866L69.8512 60.1876C69.7984 59.9497 70.2214 59.6061 70.4594 59.5532C70.6973 59.5004 71.0409 59.9235 71.0937 60.1614L70.7185 85.4604C70.7713 85.6984 70.5862 85.9891 70.3483 86.0419Z"
        fill={fill}
      />
      <path
        d="M81.8228 79.2504C81.3469 79.356 81.0033 78.933 80.9505 78.6951L80.7148 67.5127C80.662 67.2747 81.085 66.9311 81.323 66.8783C81.5609 66.8255 81.9045 67.2485 81.9573 67.4865L82.4309 78.6161C82.4837 78.854 82.2986 79.1447 81.8228 79.2504Z"
        fill={fill}
      />
      <path
        d="M84.2509 99.1833C83.775 99.289 83.4843 99.1039 83.3786 98.628L81.1884 78.6421C81.1356 78.4041 81.3207 78.1134 81.7966 78.0077C82.0345 77.9549 82.5632 78.0872 82.4309 78.6159L84.6211 98.6018C84.6739 98.8397 84.4888 99.1305 84.2509 99.1833Z"
        fill={fill}
      />
      <path
        d="M25.1147 94.8359C24.8768 94.8887 24.6389 94.9416 24.4009 94.9944C22.3918 94.9411 20.7795 93.3018 20.8328 91.2926C20.8861 89.2835 22.5254 87.6713 24.5346 87.7246C25.5391 87.7512 26.3586 88.0686 27.0457 88.9147C27.6801 89.5228 28.1293 90.4217 28.1027 91.4263C27.9437 92.9595 26.7803 94.4662 25.1147 94.8359ZM23.7941 88.8876C22.6045 89.1517 21.8112 90.0768 21.8374 91.3193C21.8636 92.5618 22.8415 93.593 24.1368 93.8047C25.3793 93.7785 26.6484 92.7478 26.6222 91.5053C26.7017 90.7387 26.3581 90.3156 26.0145 89.8926C25.671 89.4695 24.8515 89.1522 24.3756 89.2578C24.0849 89.0727 24.0321 88.8348 23.7941 88.8876Z"
        fill={fill}
      />
      <path
        d="M76.3735 95.1899C76.1356 95.2427 75.8976 95.2956 75.6597 95.3484C73.6506 95.2951 72.0383 93.6558 72.0916 91.6467C72.1449 89.6375 73.7842 88.0253 75.7934 88.0786C76.7979 88.1052 77.6174 88.4226 78.3045 89.2687C78.9389 89.8768 79.3881 90.7757 79.3614 91.7803C79.2025 93.3135 78.039 94.8201 76.3735 95.1899ZM75.0529 89.2416C73.8632 89.5057 73.07 90.4308 73.0962 91.6733C73.1224 92.9158 74.1531 94.1849 75.3956 94.1587C75.6335 94.1059 75.6335 94.1059 75.8715 94.0531C77.0611 93.789 77.8544 92.8639 77.8282 91.6214C77.9077 90.8547 77.5641 90.4317 77.2205 90.0087C76.8769 89.5856 76.0575 89.2682 75.5816 89.3739C75.2908 89.1888 75.2908 89.1888 75.0529 89.2416Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_316_3364">
        <rect width="100" height="100" fill={fill} />
      </clipPath>
    </defs>
  </SvgIcon>
);
