import React from 'react';

import { IconsProps } from './Icons.type.js';

export const FullDiscountIcon: React.FC<IconsProps> = (props) => (
  <svg
    width={props.width ?? '48'}
    height={props.height ?? '48'}
    viewBox={`0 0 ${props.width ?? '48'} ${props.height ?? '48'}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 45.4997C22.74 45.4997 21.56 45.0198 20.68 44.1198L17.64 41.0798C17.4 40.8398 16.76 40.5798 16.44 40.5798H12.12C9.52 40.5798 7.41998 38.4796 7.41998 35.8796V31.5597C7.41998 31.2397 7.15998 30.5998 6.91998 30.3598L3.88 27.3198C3 26.4398 2.5 25.2597 2.5 23.9997C2.5 22.7397 2.98 21.5596 3.88 20.6796L6.91998 17.6396C7.15998 17.3996 7.41998 16.7596 7.41998 16.4396V12.1198C7.41998 9.5198 9.52 7.41961 12.12 7.41961H16.44C16.76 7.41961 17.4 7.15961 17.64 6.91961L20.68 3.87957C22.44 2.11957 25.56 2.11957 27.32 3.87957L30.36 6.91961C30.6 7.15961 31.24 7.41961 31.56 7.41961H35.88C38.48 7.41961 40.58 9.5198 40.58 12.1198V16.4396C40.58 16.7596 40.84 17.3996 41.08 17.6396L44.12 20.6796C45 21.5596 45.5 22.7397 45.5 23.9997C45.5 25.2597 45.02 26.4398 44.12 27.3198L41.08 30.3598C40.84 30.5998 40.58 31.2397 40.58 31.5597V35.8796C40.58 38.4796 38.48 40.5798 35.88 40.5798H31.56C31.24 40.5798 30.6 40.8398 30.36 41.0798L27.32 44.1198C26.44 45.0198 25.26 45.4997 24 45.4997ZM9.03998 28.2397C9.83998 29.0397 10.42 30.4397 10.42 31.5597V35.8796C10.42 36.8196 11.18 37.5798 12.12 37.5798H16.44C17.56 37.5798 18.96 38.1596 19.76 38.9596L22.8 41.9997C23.44 42.6397 24.56 42.6397 25.2 41.9997L28.24 38.9596C29.04 38.1596 30.44 37.5798 31.56 37.5798H35.88C36.82 37.5798 37.58 36.8196 37.58 35.8796V31.5597C37.58 30.4397 38.16 29.0397 38.96 28.2397L42 25.1996C42.32 24.8796 42.5 24.4597 42.5 23.9997C42.5 23.5397 42.32 23.1197 42 22.7997L38.96 19.7597C38.16 18.9597 37.58 17.5596 37.58 16.4396V12.1198C37.58 11.1798 36.82 10.4196 35.88 10.4196H31.56C30.44 10.4196 29.04 9.83973 28.24 9.03973L25.2 5.99969C24.56 5.35969 23.44 5.35969 22.8 5.99969L19.76 9.03973C18.96 9.83973 17.56 10.4196 16.44 10.4196H12.12C11.18 10.4196 10.42 11.1798 10.42 12.1198V16.4396C10.42 17.5596 9.83998 18.9597 9.03998 19.7597L6 22.7997C5.68 23.1197 5.5 23.5397 5.5 23.9997C5.5 24.4597 5.68 24.8796 6 25.1996L9.03998 28.2397Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M30.0005 32C28.8805 32 27.9805 31.1 27.9805 30C27.9805 28.9 28.8805 28 29.9805 28C31.0805 28 31.9805 28.9 31.9805 30C31.9805 31.1 31.1005 32 30.0005 32Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M18.02 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18C20 19.1 19.12 20 18.02 20Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M17.9999 31.4999C17.6199 31.4999 17.2399 31.36 16.9399 31.06C16.3599 30.48 16.3599 29.5199 16.9399 28.9399L28.9399 16.9399C29.5199 16.3599 30.4799 16.3599 31.0599 16.9399C31.6399 17.5199 31.6399 18.48 31.0599 19.06L19.0599 31.06C18.7599 31.36 18.3799 31.4999 17.9999 31.4999Z"
      fill={props.fill || 'white'}
    />
  </svg>
);
