import clsx from 'clsx';
import { Link } from '@/npm/next.js';
import React, { type ReactNode, useCallback, useMemo } from 'react';
import { LoggedInUser } from '../../types/entities.js';
import { ThreeLinesIcon } from '../Icons/index.js';
import { LogoIcon } from '../Icons/index.js';
import { rootLinks } from '../Links.js';
import styles from './Header.module.scss';
import useModalState from '../_hooks/useModalState.js';
import { MobileMenu } from './MobileMenu/MobileMenu.js';
import { getHref } from '../../utils/getHref.js';
import { usePathname } from 'next/navigation.js';

const MenuLink: React.FC<{
  href: string;
  children: ReactNode;
  className?: string;
}> = ({ href, children, className }) => {
  const pathname = usePathname();
  const isLinkActive = useMemo(() => {
    return pathname.startsWith(href);
  }, [href, pathname]);
  return (
    <Link
      href={href}
      className={clsx(className, isLinkActive && styles.active)}
    >
      {children}
    </Link>
  );
};
interface Props {
  loggedInUser: LoggedInUser | null;
  logout: () => Promise<void>;
}

export const Header: React.FC<Props> = ({ loggedInUser, logout }) => {
  const [isMobileMenuVisible, showMobileMenu, hideMobileMenu] = useModalState();

  return (
    <header className={styles.wrapper}>
      <div className={styles.container}>
        <MenuLink href={rootLinks.main.url} className={styles.logo}>
          <LogoIcon />
        </MenuLink>
        <ul className={styles.menu}>
          {/* <li className={styles.menuItem}>
            <MenuLink
              href={rootLinks.purchases.url}
              className={styles.menuLink}
            >
              Закупки
            </MenuLink>
          </li> */}
          {/* <li className={styles.menuItem}>
            <MenuLink href={rootLinks.sections.url} className={styles.menuLink}>
              Секции ЭТП
            </MenuLink>
          </li> */}
          {/* <li className={styles.menuItem}>
            <MenuLink href={rootLinks.customers.url} className={styles.menuLink}>
              Заказчикам
            </MenuLink>
          </li> */}
          {/* <li className={styles.menuItem}>
            <MenuLink href={rootLinks.members.url} className={styles.menuLink}>
              Участникам
            </MenuLink>
          </li>
          <li className={styles.menuItem}>
            <MenuLink
              href={`${rootLinks.tariffs.url}/licenses`}
              className={styles.menuLink}
            >
              Тарифы
            </MenuLink>
          </li> */}
          {/* <li className={styles.menuItem}>
            <MenuLink href={rootLinks.info.url} className={styles.menuLink}>
              О площадке
            </MenuLink>
          </li> */}
        </ul>

        <div className={styles.phone}>
          <a href="tel:88123354489">
            <span>8 812 335 44 89</span>
          </a>
        </div>

        <div className={clsx(styles.delimiter, styles.first)} />

        {loggedInUser ? (
          <Link href={getHref('/personal')} className={styles.login}>
            <span>Войти</span>
          </Link>
        ) : (
          <Link href={rootLinks.auth.url} className={styles.login}>
            <span>Вход в кабинет</span>
          </Link>
        )}

        <div className={clsx(styles.delimiter, styles.second)} />

        <div className={styles.lines} onClick={showMobileMenu}>
          <ThreeLinesIcon />
        </div>

        {isMobileMenuVisible && (
          <MobileMenu loggedInUser={loggedInUser} onHide={hideMobileMenu} />
        )}
      </div>
    </header>
  );
};
