import moment from 'moment';
import React from 'react';
import styles from './Footer.module.scss';
import { LogoIcon } from '../Icons/index.js';
import { Link } from '@/npm/next.js';
import { rootLinks } from '../Links.js';
import { useLayoutContext } from '../LayoutContext.js';

export const Footer: React.FC = () => {
  const nowYear = moment().get('year');
  const { offerLinks } = useLayoutContext();
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.firstRow}>
          <div className={styles.col}>
            <LogoIcon />
            <p className={styles.phone}>
              <a href="tel:88123354489">
                <span>+7 812 335-44-89</span>
              </a>
            </p>
            <p className={styles.email}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="mailto:info@praktis.ru"
              >
                info@praktis.ru
              </a>
            </p>
            <p className={styles.address}>
              Россия, Санкт-Петербург, Ушаковская набережная, д.3 корп.1 /
              Московский пр. 212
            </p>
          </div>
          <ul className={styles.colMenu}>
            {/* <li>
              <Link href={rootLinks.main.url} className={styles.menuLink}>
                Главная
              </Link>
            </li>
            <li>
              <Link href={rootLinks.members.url} className={styles.menuLink}>
                Участникам
              </Link>
            </li>
            <li>
              <Link href={rootLinks.purchases.url} className={styles.menuLink}>
                Закупки
              </Link>
            </li>
            <li>
              <Link href={rootLinks.purchases.url} className={styles.menuLink}>
                О Praktis.lot
              </Link>
            </li>
            <li>
              <Link href={rootLinks.tariffs.url} className={styles.menuLink}>
                Тарифы
              </Link>
            </li> */}
          </ul>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.copyright}>
            <span>©</span>
            <span>{nowYear}</span>
            <span>«SetlGroup»</span>
          </div>
          <ul className={styles.rightLinks}>
            <li>
              <a
                href={offerLinks?.[0]?.link || ''}
                className={styles.menuLink}
                download
              >
                Оферта на заключение лицензионного договора
              </a>
            </li>
            <li>
              <a
                href={offerLinks?.[1]?.link || ''}
                className={styles.menuLink}
                download
              >
                Политика конфиденциальности
              </a>
            </li>
            <li>
              <Link href={rootLinks.programs.url} className={styles.menuLink}>
                Программные модули
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
