import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Картинка с лопатой и кучей */
export const MaterialsIcon: FC<IconsProps> = ({ fill = 'white' }) => (
  <SvgIcon width={100} height={100} noFill noStroke>
    <g clipPath="url(#clip0_316_2917)">
      <path
        d="M99.3925 99.4413L0.705552 99.6504C0.352778 99.577 0.213062 99.3642 0.0733468 99.1513C0.146688 98.7992 0.359746 98.6598 0.572804 98.5204L99.2231 98.4874C99.5759 98.5608 99.7156 98.7736 99.8553 98.9865C99.9584 99.3754 99.7453 99.5147 99.3925 99.4413Z"
        fill={fill}
      />
      <path
        d="M6.95907 99.6663C6.60629 99.5928 6.46658 99.38 6.32686 99.1672C6.22895 93.4513 9.37933 88.0445 14.3827 85.2282C14.5958 85.0888 14.9485 85.1623 15.0882 85.3751C15.228 85.588 15.1546 85.9401 14.9416 86.0795C10.3276 88.7931 7.46365 93.7084 7.42185 99.2115C7.56157 99.4243 7.34851 99.5637 6.95907 99.6663Z"
        fill={fill}
      />
      <path
        d="M14.9419 86.0799C14.5891 86.0064 14.4494 85.7936 14.3096 85.5808C14.3148 80.2538 16.826 75.2651 21.0208 71.913C24.0769 69.6098 27.6552 68.334 31.3663 68.1882C33.9458 68.1742 36.7679 68.762 39.1273 69.8045C39.4801 69.8779 39.5832 70.2669 39.3334 70.5823C39.2601 70.9345 38.8706 71.0371 38.5545 70.7875C36.1951 69.745 33.7257 69.2308 31.1463 69.2448C27.6116 69.4273 24.2463 70.5637 21.4032 72.7275C17.4582 75.7642 15.16 80.6136 15.0519 85.5516C15.368 85.8012 15.1182 86.1166 14.9419 86.0799Z"
        fill={fill}
      />
      <path
        d="M31.7123 69.1788L31.5359 69.1421C31.1831 69.0686 31.2565 68.7165 31.3298 68.3643C31.9026 67.3812 32.4754 66.3981 33.1879 65.6279C34.826 63.9481 36.9566 62.5545 39.2933 61.9388C41.4535 61.2863 43.8566 61.2356 46.1129 61.8892C46.4657 61.9626 46.6054 62.1755 46.5321 62.5276C46.4587 62.8798 46.2457 63.0192 45.8929 62.9457C43.8129 62.3288 41.7627 62.453 39.6391 62.9294C37.4788 63.5819 35.5979 64.66 33.9599 66.3398C33.2473 67.1101 32.7112 67.917 32.1751 68.724C32.1017 69.0762 31.8887 69.2156 31.7123 69.1788Z"
        fill={fill}
      />
      <path
        d="M87.943 99.6286C87.5903 99.5551 87.4506 99.3423 87.3108 99.1294C87.4993 92.922 84.1164 87.0733 78.4127 84.4156C78.0599 84.3422 77.9568 83.9533 78.2066 83.6378C78.2799 83.2856 78.6694 83.183 78.9855 83.4326C85.0053 86.3397 88.7043 92.4381 88.4425 98.9977C88.3692 99.3498 88.1561 99.4892 87.943 99.6286Z"
        fill={fill}
      />
      <path
        d="M78.9333 99.5898C78.5805 99.5163 78.4408 99.3035 78.3011 99.0907C78.2486 96.6913 77.2409 94.4605 75.381 92.7871C75.2413 92.5742 75.1382 92.1853 75.3513 92.046C75.5643 91.9066 75.9538 91.804 76.0935 92.0168C78.1298 93.727 79.417 96.3834 79.3961 99.135C79.5358 99.3478 79.3227 99.4871 78.9333 99.5898Z"
        fill={fill}
      />
      <path
        d="M78.8023 84.3137C78.626 84.2769 78.3099 84.0274 78.3465 83.8513C78.0147 81.0263 76.8672 78.5827 74.6545 76.8358C73.5665 75.8743 72.4051 75.265 71.2071 74.8318C70.8543 74.7583 70.7513 74.3694 70.7879 74.1933C70.8613 73.8411 71.2507 73.7385 71.4271 73.7752C72.8015 74.2452 74.1393 74.8912 75.2273 75.8527C77.5797 77.8124 79.0433 80.5056 79.3385 83.5067C79.2285 84.035 79.1551 84.3871 78.8023 84.3137Z"
        fill={fill}
      />
      <path
        d="M21.4297 83.2053C20.9006 83.0951 20.7975 82.7061 20.8342 82.5301C21.4507 80.4537 22.8757 78.9132 24.7199 78.0112C26.564 77.1092 28.8273 76.8456 30.6943 77.6019C31.047 77.6753 31.1501 78.0643 31.1134 78.2403C31.0401 78.5925 30.6506 78.6951 30.4742 78.6584C28.747 78.115 26.8732 78.2759 25.242 79.0386C23.6109 79.8013 22.399 81.2024 21.8559 82.9265C21.8192 83.1026 21.6061 83.242 21.4297 83.2053Z"
        fill={fill}
      />
      <path
        d="M63.6133 51.2027L63.4369 51.166C63.0842 51.0925 63.1575 50.7404 63.2308 50.3882L75.6996 27.6308C75.773 27.2786 76.1258 27.3521 76.4785 27.4255C76.8313 27.499 76.758 27.8512 76.6846 28.2034L64.2158 50.9608C64.0028 51.1001 63.7897 51.2395 63.6133 51.2027Z"
        fill={fill}
      />
      <path
        d="M70.7212 55.0719L70.5448 55.0352C70.1921 54.9617 70.2654 54.6095 70.3388 54.2573L82.8076 31.4999C82.8809 31.1478 83.2337 31.2212 83.5864 31.2947C83.9392 31.3682 83.8659 31.7203 83.7925 32.0725L71.3237 54.8299C71.1107 54.9693 70.8976 55.1086 70.7212 55.0719Z"
        fill={fill}
      />
      <path
        d="M65.1189 66.9493L64.9426 66.9126C64.5898 66.8391 64.6631 66.4869 64.7365 66.1348L70.7211 55.0715L63.2971 50.9533L57.3125 62.0165C57.2391 62.3687 56.8863 62.2953 56.5336 62.2218C56.1808 62.1483 56.2541 61.7962 56.3275 61.444L62.5986 49.8892C62.6352 49.7131 62.8116 49.7498 62.8483 49.5737C63.0247 49.6105 63.2011 49.6472 63.2011 49.6472L71.4703 54.1252C71.8231 54.1986 71.7498 54.5508 71.6764 54.903L65.442 66.2817C65.5084 66.8467 65.2953 66.986 65.1189 66.9493Z"
        fill={fill}
      />
      <path
        d="M96.3174 20.534C96.141 20.4972 95.7882 20.4238 95.4355 20.3503L78.8969 11.3944C78.0517 11.0346 77.7425 9.8679 78.242 9.23701L80.0337 5.93564C80.3935 5.09193 81.5618 4.78406 82.194 5.28317L98.7325 14.2391C99.5778 14.5989 99.8869 15.7656 99.3875 16.3965L97.5957 19.6978C97.4857 20.2261 96.8832 20.4681 96.3174 20.534ZM81.6579 6.09015C81.4815 6.05342 81.2684 6.19277 81.2318 6.36886L79.44 9.67023C79.1903 9.98567 79.2933 10.3746 79.6461 10.448L96.1847 19.404C96.5374 19.4774 96.9269 19.3748 96.9635 19.1987L98.7553 15.8974C99.005 15.5819 98.902 15.193 98.5492 15.1195L82.0106 6.16361C81.8343 6.12688 81.6579 6.09015 81.6579 6.09015Z"
        fill={fill}
      />
      <path
        d="M84.7039 32.9969C84.5276 32.9601 84.1748 32.8867 83.9984 32.8499L74.7441 27.7994C74.428 27.5498 74.1119 27.3003 74.0089 26.9114C73.9059 26.5225 73.9792 26.1703 74.0892 25.642L75.9176 22.1646C76.2407 21.497 77.2326 21.1524 78.0779 21.5121L87.3321 26.5626C88.1774 26.9224 88.3468 27.8763 87.9871 28.72L86.1587 32.1975C85.8723 32.689 85.2698 32.931 84.7039 32.9969ZM77.3287 22.4584C77.1523 22.4217 76.9393 22.5611 76.9026 22.7371L75.0742 26.2146C75.0375 26.3907 75.0375 26.3907 75.0008 26.5668C74.9642 26.7429 75.1406 26.7796 75.3169 26.8163L84.5712 31.8669C84.7476 31.9036 85.1004 31.9771 85.1737 31.6249L87.0021 28.1474C87.0388 27.9713 87.1121 27.6192 86.7593 27.5457L77.6815 22.5319C77.5051 22.4952 77.5051 22.4952 77.3287 22.4584Z"
        fill={fill}
      />
      <path
        d="M76.4765 23.0158L76.3001 22.9791C75.9474 22.9056 76.0207 22.5535 76.0941 22.2013C76.7035 21.0421 77.1069 19.1052 77.3706 16.9554C77.6709 14.6295 78.1476 12.3403 78.8671 10.6529C78.9404 10.3007 79.3299 10.1981 79.646 10.4477C79.9988 10.5211 80.1018 10.9101 79.8521 11.2255C79.1326 12.9129 78.6925 15.026 78.4289 17.1758C78.1652 19.3256 77.7252 21.4386 77.079 22.7739C76.866 22.9132 76.6529 23.0526 76.4765 23.0158Z"
        fill={fill}
      />
      <path
        d="M87.5608 28.9989L87.3844 28.9621C87.0316 28.8887 87.1049 28.5365 87.1783 28.1843C87.8244 26.8491 89.3892 25.5214 91.1669 24.0544C92.7684 22.5506 94.5462 21.0836 95.4054 19.609C95.6551 19.2936 95.8315 19.3303 96.1842 19.4038C96.5004 19.6533 96.4637 19.8294 96.3903 20.1816C95.3181 21.7955 93.5036 23.4386 91.9022 24.9424C90.3375 26.2701 88.7727 27.5977 88.1632 28.7569C87.9502 28.8962 87.7371 29.0356 87.5608 28.9989Z"
        fill={fill}
      />
      <path
        d="M85.1262 23.8986C84.384 23.9277 83.6785 23.7808 83.186 23.4945C82.201 22.9219 81.4658 22.0339 81.1566 20.8672C80.4353 18.1448 80.8823 15.1146 82.4907 12.6937C82.7404 12.3782 82.9168 12.415 83.2696 12.4884C83.5857 12.738 83.549 12.9141 83.4757 13.2662C82.0804 15.5478 81.7067 18.2259 82.2883 20.7354C82.4944 21.5132 83.0899 22.1884 83.7221 22.6875C84.391 23.0106 85.2729 23.1942 86.0885 22.8129C88.4618 22.0211 90.4894 20.2386 91.745 17.7442C91.8183 17.392 92.2077 17.2894 92.5238 17.539C92.8766 17.6124 92.9797 18.0013 92.7299 18.3168C91.4377 20.9873 89.1604 23.0852 86.6107 23.8403C85.8685 23.8694 85.5157 23.796 85.1262 23.8986Z"
        fill={fill}
      />
      <path
        d="M39.0508 79.8931C38.8744 79.8564 38.8744 79.8564 39.0508 79.8931C38.698 79.8196 38.5583 79.6068 38.4186 79.394C38.6892 76.327 39.489 73.3703 40.9943 70.5604L48.1979 57.1789C48.2346 57.0028 48.411 57.0395 48.4477 56.8634C48.624 56.9002 48.8004 56.9369 48.8004 56.9369L56.8933 61.3781C57.2461 61.4516 57.1728 61.8038 57.0994 62.1559C57.0261 62.5081 56.6733 62.4346 56.3205 62.3612L48.7201 58.2062L41.8029 71.0963C40.3343 73.73 39.5711 76.5107 39.3005 79.5777C39.4402 79.7905 39.2272 79.9298 39.0508 79.8931Z"
        fill={fill}
      />
      <path
        d="M59.5956 91.7044C59.4192 91.6676 59.2428 91.6309 59.1031 91.4181C58.9634 91.2052 59.0367 90.8531 59.2498 90.7137C61.8431 88.8654 63.9807 86.5546 65.4493 83.9209L72.3665 71.0309L64.7661 66.8759C64.4134 66.8024 64.4867 66.4503 64.56 66.0981C64.6334 65.7459 64.9862 65.8194 65.3389 65.8929L73.4318 70.3341C73.6082 70.3708 73.5715 70.5469 73.7479 70.5836C73.7112 70.7597 73.6746 70.9358 73.6746 70.9358L66.471 84.3174C64.9656 87.1272 62.5784 89.7534 59.985 91.6017C59.8086 91.565 59.7719 91.7411 59.5956 91.7044Z"
        fill={fill}
      />
      <path
        d="M41.9165 79.3879C41.5637 79.3144 41.424 79.1016 41.3209 78.7127C41.5916 75.6457 42.428 72.5129 43.9334 69.703L50.2044 58.1483C50.2778 57.7961 50.6305 57.8695 50.9833 57.943C51.3361 58.0165 51.2627 58.3687 51.1894 58.7208L44.9183 70.2756C43.4497 72.9094 42.6499 75.8661 42.4159 78.757C42.4823 79.322 42.2692 79.4614 41.9165 79.3879Z"
        fill={fill}
      />
      <path
        d="M58.5211 88.9086C58.3447 88.8719 58.1683 88.8351 58.0286 88.6223C57.8889 88.4095 57.9623 88.0573 58.1753 87.918C60.5923 86.0329 62.7298 83.7221 64.2352 80.9123L70.5062 69.3575C70.5796 69.0053 70.9323 69.0788 71.2851 69.1523C71.6379 69.2257 71.5645 69.5779 71.4912 69.9301L65.2201 81.4849C63.6781 84.4708 61.5039 86.9576 58.9106 88.806C58.7342 88.7692 58.5211 88.9086 58.5211 88.9086Z"
        fill={fill}
      />
      <path
        d="M54.8203 78.4003C54.2545 78.4662 53.762 78.1799 53.4092 78.1065C52.0714 77.4604 51.4829 75.868 51.9526 74.4961L56.2537 61.7961C56.3271 61.444 56.7165 61.3413 56.8929 61.3781C57.2457 61.4515 57.3487 61.8404 57.3121 62.0165L53.0109 74.7165C52.6512 75.5602 53.1734 76.5875 53.982 77.1234C54.7906 77.6592 55.9589 77.3514 56.4584 76.7205L64.7361 66.1347C64.8094 65.7826 65.1622 65.856 65.515 65.9295C65.6547 66.1423 65.7577 66.5312 65.5447 66.6706L57.267 77.2563C56.5544 78.0266 55.7389 78.4079 54.8203 78.4003Z"
        fill={fill}
      />
      <path
        d="M60.3764 95.909C60.0236 95.8355 59.8839 95.6227 59.9206 95.4466C59.7424 91.0001 57.83 86.9274 54.6026 83.8668C47.9714 77.709 37.404 78.0805 31.2411 84.6972C31.028 84.8366 30.6386 84.9392 30.4989 84.7264C30.3592 84.5136 30.2561 84.1246 30.4692 83.9853C37.0949 76.9137 48.2281 76.4764 55.3151 83.0966C58.6822 86.3699 60.7343 90.6555 60.8759 95.2781C60.8025 95.6303 60.5895 95.7696 60.3764 95.909Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_316_2917">
        <rect width="100" height="100" fill={fill} />
      </clipPath>
    </defs>
  </SvgIcon>
);
