import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Иконка галочки внутри восьмиконечной звезды */
export const VerifyIcon: React.FC<IconsProps> = ({ className }) => (
  <SvgIcon width={32} height={32} className={className} noFill noStroke>
    <g id="verify">
      <path
        d="M14.3874 20.2267C14.1207 20.2267 13.8674 20.12 13.6807 19.9334L10.4541 16.7067C10.0674 16.32 10.0674 15.68 10.4541 15.2934C10.8407 14.9067 11.4807 14.9067 11.8674 15.2934L14.3874 17.8134L20.1207 12.08C20.5074 11.6934 21.1474 11.6934 21.5341 12.08C21.9207 12.4667 21.9207 13.1067 21.5341 13.4934L15.0941 19.9334C14.9074 20.12 14.6541 20.2267 14.3874 20.2267Z"
        fill="#2D9CDB"
      />
      <path
        d="M15.9985 30.3337C15.1585 30.3337 14.3185 30.0537 13.6652 29.4937L11.5585 27.6803C11.3452 27.4937 10.8119 27.307 10.5319 27.307H8.23854C6.26521 27.307 4.66521 25.707 4.66521 23.7337V21.4537C4.66521 21.1737 4.47854 20.6537 4.29188 20.4403L2.49188 18.3203C1.39854 17.027 1.39854 14.987 2.49188 13.6937L4.29188 11.5737C4.47854 11.3603 4.66521 10.8403 4.66521 10.5603V8.26699C4.66521 6.29366 6.26521 4.69366 8.23854 4.69366H10.5452C10.8252 4.69366 11.3585 4.49366 11.5719 4.32033L13.6785 2.50699C14.9852 1.38699 17.0252 1.38699 18.3319 2.50699L20.4385 4.32033C20.6519 4.50699 21.1852 4.69366 21.4652 4.69366H23.7319C25.7052 4.69366 27.3052 6.29366 27.3052 8.26699V10.5337C27.3052 10.8137 27.5052 11.347 27.6919 11.5603L29.5052 13.667C30.6252 14.9737 30.6252 17.0137 29.5052 18.3203L27.6919 20.427C27.5052 20.6403 27.3052 21.1737 27.3052 21.4537V23.7203C27.3052 25.6937 25.7052 27.2937 23.7319 27.2937H21.4652C21.1852 27.2937 20.6519 27.4937 20.4385 27.667L18.3319 29.4803C17.6785 30.0537 16.8385 30.3337 15.9985 30.3337ZM8.23854 6.69366C7.37187 6.69366 6.66521 7.40033 6.66521 8.26699V10.547C6.66521 11.307 6.30521 12.2803 5.81188 12.8537L4.01188 14.9737C3.54521 15.5203 3.54521 16.467 4.01188 17.0137L5.81188 19.1337C6.30521 19.7203 6.66521 20.6803 6.66521 21.4403V23.7203C6.66521 24.587 7.37187 25.2937 8.23854 25.2937H10.5452C11.3185 25.2937 12.2919 25.6537 12.8785 26.1603L14.9852 27.9737C15.5319 28.4403 16.4919 28.4403 17.0385 27.9737L19.1452 26.1603C19.7319 25.667 20.7052 25.2937 21.4785 25.2937H23.7452C24.6119 25.2937 25.3185 24.587 25.3185 23.7203V21.4537C25.3185 20.6803 25.6785 19.707 26.1852 19.1203L27.9985 17.0137C28.4652 16.467 28.4652 15.507 27.9985 14.9603L26.1852 12.8537C25.6785 12.267 25.3185 11.2937 25.3185 10.5203V8.26699C25.3185 7.40033 24.6119 6.69366 23.7452 6.69366H21.4785C20.7052 6.69366 19.7319 6.33366 19.1452 5.82699L17.0385 4.01366C16.4919 3.54699 15.5319 3.54699 14.9852 4.01366L12.8785 5.84033C12.2919 6.33366 11.3052 6.69366 10.5452 6.69366H8.23854Z"
        fill="#2D9CDB"
      />
    </g>
  </SvgIcon>
);
