import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';
import { SvgIcon } from '../../base-components/SvgIcon.js';

/** Иконка с лупой */
export const SearchIcon: FunctionComponent<IconsProps> = ({
  className,
  fill = '#282828',
}) => (
  <SvgIcon width={16} height={16} className={className} noFill noStroke>
    <path
      d="M12.3334 12.3333L14.6667 14.6666M14 7.66665C14 4.16884 11.1645 1.33331 7.66671 1.33331C4.1689 1.33331 1.33337 4.16884 1.33337 7.66665C1.33337 11.1644 4.1689 14 7.66671 14C11.1645 14 14 11.1644 14 7.66665Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
