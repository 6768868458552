import { OfferProps } from '../Tariffs/Offer/index.js';

export const tariffNames = ['licenses', 'subscription', 'vipLicenses'] as const;
export type TariffNames = (typeof tariffNames)[number];
export const tariffTitles: Record<TariffNames, string> = {
  licenses: 'Лицензии стандарт',
  subscription: 'Подписка',
  vipLicenses: 'Лицензии VIP',
};

export const tariffsDummy: {
  services: { customers: OfferProps[]; participants: OfferProps[] };
  licenses: OfferProps[];
  vipLicenses: OfferProps[];
} = {
  services: {
    customers: [
      {
        list: [
          'Регистрация на портале Praktis.lot',
          'Проведение процедур закупок',
          'Юридическое и техническое сопровождение',
          'Подбор подрядчиков/ поставщиков',
          'Проверка по РНП (реестр недобросовестных поставщиков)',
        ],
        action: 'free',
        tariffTitle: 'Стандарт',
      },
      {
        tariffTitle: 'Партнёр',
        action: 'connect',
        color: 'free',
        conditions: {
          price: '6 600 ₽',
          withNds: false,
        },
        price: 6600,
        additional: ['С закупки, в которой есть', 'хотя бы один участник'],
      },
      {
        tariffTitle: 'VIP',
        action: 'connect',
        color: 'free',
        conditions: {
          price: '500 000 ₽',
          withNds: false,
        },
        vipLink: {
          title: 'Что входит в тариф?',
          url: '#',
        },
      },
    ],
    participants: [
      {
        title: 'Ускоренная аккредитация',
        name: 'fast_akk',
        action: 'buy',
        color: 'buy',
        conditions: {
          price: '6 000 ₽',
          withNds: false,
        },
        additional: ['В течение 3 часов'],
        link: '#',
        description:
          'Стандартная аккредитация в соответствии с действующим договором оферты осуществляется в течение 7-ми дней. Ускоренная в течение 3 часов. Активацию необходимо подтвердить в контактном центре.',
      },
      {
        title: 'Ускоренное зачисление',
        name: 'prepayment',
        action: 'buy',
        color: 'buy',
        conditions: {
          price: '2 000 ₽',
          withNds: false,
        },
        additional: ['В течение 3 часов'],
        link: '#',
        description:
          'Стандартное зачисление денежных средств на лицевой счет в соответствии с договором оферты осуществляется на следующий рабочий день. Ускоренное в течение 3 часов. (При предъявлении платежных документов) Активацию необходимо подтвердить в контактном центре.',
      },
      {
        title: 'Проверка контрагента',
        name: 'org_check',
        action: 'buy',
        color: 'buy',
        conditions: {
          price: '300 ₽',
          withNds: false,
        },
        additional: [
          'Позволяет получить информацию о надежности Контрагента по 28 параметрам',
        ],
        link: '#',
      },
      {
        title: '«Облачное хранилище»',
        name: 'cloud',
        action: 'buy',
        color: 'buy',
        conditions: {
          price: '6 000 ₽',
          withNds: false,
          ndsText: '(НДС не облагается)',
        },
        additional: ['доступ на 30 дней'],
        link: '#',
      },
    ],
  },
  licenses: [
    {
      title: 'Бесплатно',
      name: 'g0_l100',
      action: 'none',
      color: 'connect',
      columns: [
        'НМЦ до 99\u00A0999,\u00A099\u00A0₽',
        'Бесплатно',
        'Бесплатно',
        'Бесплатно',
      ],
      tariffType: 'single',
    },
    {
      title: 'Лицензия за участие',
      name: 'G100',
      action: 'none',
      color: 'connect',
      columns: [
        'НМЦ от 100\u00A0000\u00A0₽',
        '1,5% от НМЦ, но не менее 7\u00A0500\u00A0₽ и не более 100\u00A0000\u00A0₽',
        '0,5% от НМЦ, но не менее 7\u00A0500\u00A0₽ и не более 80\u00A0000\u00A0₽',
        '2% от НМЦ, но не менее 8\u00A0000\u00A0₽',
      ],
      tariffType: 'single',
    },
    {
      title: 'С победителя закупки',
      name: 'e0',
      action: 'none',
      color: 'buy',
      columns: [
        'НМЦ не указана или равна 0',
        '12 000 ₽',
        '10 000 ₽',
        '16 500 ₽',
      ],
      tariffType: 'single',
    },
    {
      title: 'С победителя закупки',
      name: 'mzcd',
      action: 'none',
      color: 'buy',
      columns: ['Торги за единицу', '7 500 ₽', '7 500 ₽', '9 000 ₽'],
      description: 'Разовая лицензия с победителя',
      tariffType: 'single',
    },
  ],
  vipLicenses: [
    {
      title: 'Бесплатно (VIP)',
      name: 'vip_g0_l100',
      action: 'none',
      color: 'buy',
      columns: [
        'НМЦ до 99\u00A0999,\u00A099\u00A0₽',
        // 'Бесплатно',
        // 'Бесплатно',
        'Бесплатно',
      ],
      tariffType: 'single',
    },
    {
      title: 'С победителя закупки (VIP)',
      name: 'vip_G100',
      action: 'none',
      color: 'buy',
      columns: [
        `НМЦ от 100\u00A0000\u00A0₽`,
        // '0,28% от НМЦ, но не менее 3\u00A0850\u00A0₽ и не более 14\u00A0500\u00A0₽',
        // '0,28% от НМЦ, но не менее 3\u00A0850\u00A0₽ и не более 14\u00A0000\u00A0₽',
        '0,28% от НМЦ, но не менее 3\u00A0850\u00A0₽ и не более 16\u00A0500\u00A0₽, включая НДС',
      ],
      conditions: {
        withNds: false,
      },
      tariffType: 'single',
    },
    {
      title: 'С победителя закупки (VIP)',
      name: 'vip_e0',
      action: 'none',
      color: 'buy',
      columns: [
        'НМЦ не указана или равна 0',
        // '14\u00A0500\u00A0₽',
        // '14\u00A0000\u00A0₽',
        '16\u00A0500\u00A0₽, включая НДС',
      ],
      tariffType: 'single',
    },
  ],
};
