import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Иконка с наконечкиком письменного пера в кватратике */
export const BrushIcon: React.FC<IconsProps> = ({
  className,
  fill = '#2D9CDB',
}) => (
  <SvgIcon width={32} height={32} className={className} noFill noStroke>
    <g id="brush">
      <path
        d="M20.0535 17.3604C19.8002 17.3604 19.5335 17.2537 19.3469 17.067L14.9602 12.6804C14.6269 12.347 14.5735 11.8404 14.8269 11.4404L16.2002 9.26702C16.6402 8.56035 17.3335 8.12035 18.1202 8.04035C18.9869 7.94702 19.8935 8.29369 20.5869 8.98702L23.0535 11.4537C23.7202 12.1204 24.0535 13.027 23.9602 13.9204C23.8669 14.7204 23.4402 15.4137 22.7735 15.8404L20.6002 17.2137C20.4135 17.3204 20.2269 17.3604 20.0535 17.3604ZM16.9335 11.827L20.2002 15.0937L21.6935 14.147C21.8802 14.027 21.9469 13.8404 21.9602 13.6937C21.9869 13.4137 21.8669 13.0937 21.6269 12.8537L19.1602 10.4004C18.8935 10.1337 18.5869 10.0004 18.3335 10.027C18.1469 10.0537 18.0002 10.147 17.8802 10.3337L16.9335 11.827Z"
        fill={fill}
      />
      <path
        d="M11.0536 23.9734C10.2269 23.9734 9.4536 23.6667 8.90693 23.12C8.26693 22.48 7.96027 21.5467 8.08027 20.56L8.88027 13.8C9.14693 11.5467 10.2403 10.64 12.5336 10.7867L15.7203 10.9734C16.2669 11.0134 16.6936 11.48 16.6536 12.0267C16.6136 12.5734 16.1469 13 15.6003 12.96L12.4003 12.7867C11.1069 12.7067 10.9869 12.9067 10.8536 14.04L10.0536 20.8C10.0136 21.1734 10.1069 21.5067 10.3069 21.7067C10.5203 21.92 10.8403 22.0134 11.2136 21.96L17.9736 21.16C19.2003 21.0134 19.3469 20.8267 19.2403 19.6534L19.0403 16.4267C19.0136 15.88 19.4269 15.4 19.9736 15.3734C20.5203 15.3334 21.0003 15.76 21.0269 16.3067L21.2136 19.4934C21.4403 21.7334 20.5069 22.88 18.2003 23.1467L11.4403 23.9467C11.3203 23.9734 11.1869 23.9734 11.0536 23.9734Z"
        fill={fill}
      />
      <path
        d="M9.98495 23.0268C9.73161 23.0268 9.47828 22.9335 9.27828 22.7335C8.89161 22.3468 8.89161 21.7068 9.27828 21.3201L11.7449 18.8535C12.1316 18.4668 12.7716 18.4668 13.1583 18.8535C13.5449 19.2401 13.5449 19.8801 13.1583 20.2668L10.6916 22.7335C10.5049 22.9335 10.2516 23.0268 9.98495 23.0268Z"
        fill={fill}
      />
      <path
        d="M20.0013 30.3337H12.0013C4.7613 30.3337 1.66797 27.2403 1.66797 20.0003V12.0003C1.66797 4.76033 4.7613 1.66699 12.0013 1.66699H20.0013C27.2413 1.66699 30.3346 4.76033 30.3346 12.0003V20.0003C30.3346 27.2403 27.2413 30.3337 20.0013 30.3337ZM12.0013 3.66699C5.85464 3.66699 3.66797 5.85366 3.66797 12.0003V20.0003C3.66797 26.147 5.85464 28.3337 12.0013 28.3337H20.0013C26.148 28.3337 28.3346 26.147 28.3346 20.0003V12.0003C28.3346 5.85366 26.148 3.66699 20.0013 3.66699H12.0013Z"
        fill={fill}
      />
    </g>
  </SvgIcon>
);
