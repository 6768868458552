import { Link } from '@/npm/next.js';
import { useRouter } from 'next/router.js';
import { useEffect, useRef } from 'react';
import { useClickAway, usePrevious } from 'react-use';
import { LoggedInUser } from '../../../types/entities.js';
import { getHref } from '../../../utils/getHref.js';
import { CloseIcon } from '../../Icons/index.js';
import { rootLinks } from '../../Links.js';
import styles from './MobileMenu.module.scss';

export type MobileMenuProps = {
  loggedInUser: LoggedInUser | null;
  onHide: () => void;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({
  loggedInUser,
  onHide,
}) => {
  const router = useRouter();
  const previousRouter = usePrevious(router);
  const ref = useRef(null);
  useClickAway(ref, () => {
    onHide();
  });
  useEffect(() => {
    if (previousRouter !== undefined) {
      onHide();
    }
  }, [onHide, previousRouter]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container} ref={ref}>
        <CloseIcon fill="#333333" onClick={onHide} opacity="1" />
        {loggedInUser ? (
          <Link href={getHref('/personal')} className={styles.login}>
            <h1>Вход в кабинет</h1>
          </Link>
        ) : (
          <Link href={rootLinks.auth.url} className={styles.login}>
            <h1>Вход в кабинет</h1>
          </Link>
        )}

        <ul className={styles.mobileMenu}>
          {/* <li className={styles.menuItem}>
            <Link href={rootLinks.purchases.url} className={styles.menuLink}>
              Закупки
            </Link>
          </li> */}
          {/* <li className={styles.menuItem}>
            <Link href={rootLinks.sections.url} className={styles.menuLink}>
              Секции ЭТП
            </Link>
          </li> */}
          {/* <li className={styles.menuItem}>
            <Link href={rootLinks.customers.url} className={styles.menuLink}>
              Заказчикам
            </Link>
          </li> */}
          {/* <li className={styles.menuItem}>
            <Link href={rootLinks.members.url} className={styles.menuLink}>
              Участникам
            </Link>
          </li>
          <li className={styles.menuItem}>
            <Link
              href={`${rootLinks.tariffs.url}/licenses`}
              className={styles.menuLink}
            >
              Тарифы
            </Link>
          </li> */}
          {/* <li className={styles.menuItem}>
            <Link href={rootLinks.info.url} className={styles.menuLink}>
              О площадке
            </Link>
          </li> */}
        </ul>

        <div className={styles.phone}>
          <a href="tel:88123354489">
            <span>8 812 335 44 89</span>
          </a>
        </div>
      </div>
    </div>
  );
};
