import { ReactNode, useEffect } from 'react';
import { useMemo } from 'react';
import React from 'react';
import { useBreadcrumbsContext } from './_shared/Breadcrumbs/BreadcrumbsContext.js';
import { Breadcrumbs } from './_shared/index.js';
import { Footer } from './Footer/index.js';
import { Header } from './Header/index.js';
import styles from './Layout.module.scss';
import { LayoutProviderProps } from './LayoutContext.js';
import { LayoutProvider } from './LayoutContext.js';
import Meta from './Meta.js';
import { useUserContext } from './UserContext.js';
import { useRouter } from 'next/router.js';
import clsx from 'clsx';

export const Layout = ({ children }: { children?: ReactNode }) => {
  const { user, logout } = useUserContext();
  const { breadcrumbs } = useBreadcrumbsContext();
  const router = useRouter();
  const isGrayBackground = useMemo(
    () =>
      router.pathname.startsWith('/purchases') ||
      router.pathname.startsWith('/test_eds'),
    [router.pathname],
  );
  const isWhiteFooter = useMemo(() => {
    return (
      router.pathname.startsWith('/purchases') ||
      router.pathname.startsWith('/members') ||
      router.pathname.startsWith('/test_eds')
    );
  }, [router.pathname]);
  /** Редирект на страницу авторизации в рамках задачи TP-87 */
  useEffect(() => {
    if (
      !(
        router.pathname.startsWith('/auth') ||
        router.pathname.startsWith('/test_eds') ||
        router.pathname.startsWith('/info/programs') ||
        router.pathname.startsWith('/purchases')
      )
    ) {
      router.push('/purchases');
    }
  }, [router]);
  const layoutParams = useMemo<LayoutProviderProps['initialValues']>(
    () => ({
      embedded: false,
    }),
    [],
  );

  return (
    <LayoutProvider initialValues={layoutParams}>
      <Meta />
      <div
        className={clsx(
          styles.wrapper,
          isGrayBackground && styles.isGrayBackground,
          isWhiteFooter && styles.isWhiteFooter,
        )}
      >
        <Header loggedInUser={user} logout={logout} />
        <section className={styles.section}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          {children}
        </section>
        <Footer />
      </div>
    </LayoutProvider>
  );
};
