import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Иконка с двумя человечками */
export const TwoUsersIcon: React.FC<IconsProps> = ({
  className,
  fill = '#2D9CDB',
}) => (
  <SvgIcon width={32} height={32} className={className} noFill noStroke>
    <g id="2users">
      <path
        d="M12.2115 15.4937C12.1715 15.4937 12.1448 15.4937 12.1048 15.4937C12.0381 15.4803 11.9448 15.4803 11.8648 15.4937C7.99812 15.3737 5.07812 12.3337 5.07812 8.58699C5.07812 4.77366 8.18479 1.66699 11.9981 1.66699C15.8115 1.66699 18.9181 4.77366 18.9181 8.58699C18.9048 12.3337 15.9715 15.3737 12.2515 15.4937C12.2381 15.4937 12.2248 15.4937 12.2115 15.4937ZM11.9981 3.66699C9.29146 3.66699 7.07812 5.88033 7.07812 8.58699C7.07812 11.2537 9.15812 13.4003 11.8115 13.4937C11.8915 13.4803 12.0648 13.4803 12.2381 13.4937C14.8515 13.3737 16.9048 11.227 16.9181 8.58699C16.9181 5.88033 14.7048 3.66699 11.9981 3.66699Z"
        fill={fill}
      />
      <path
        d="M22.0512 15.6663C22.0112 15.6663 21.9712 15.6663 21.9312 15.653C21.3845 15.7063 20.8245 15.3197 20.7712 14.773C20.7179 14.2263 21.0512 13.733 21.5979 13.6663C21.7579 13.653 21.9312 13.653 22.0779 13.653C24.0245 13.5463 25.5445 11.9463 25.5445 9.98634C25.5445 7.95967 23.9045 6.31967 21.8779 6.31967C21.3312 6.33301 20.8779 5.87967 20.8779 5.33301C20.8779 4.78634 21.3312 4.33301 21.8779 4.33301C24.9979 4.33301 27.5445 6.87967 27.5445 9.99967C27.5445 13.0663 25.1445 15.5463 22.0912 15.6663C22.0779 15.6663 22.0645 15.6663 22.0512 15.6663Z"
        fill={fill}
      />
      <path
        d="M12.2261 30.0663C9.61281 30.0663 6.98615 29.3997 4.99948 28.0663C3.14615 26.8397 2.13281 25.1597 2.13281 23.333C2.13281 21.5063 3.14615 19.813 4.99948 18.573C8.99948 15.9197 15.4795 15.9197 19.4528 18.573C21.2928 19.7997 22.3195 21.4797 22.3195 23.3063C22.3195 25.133 21.3061 26.8263 19.4528 28.0663C17.4528 29.3997 14.8395 30.0663 12.2261 30.0663ZM6.10615 20.253C4.82615 21.1063 4.13281 22.1997 4.13281 23.3463C4.13281 24.4797 4.83948 25.573 6.10615 26.413C9.42615 28.6397 15.0261 28.6397 18.3461 26.413C19.6261 25.5597 20.3195 24.4663 20.3195 23.3197C20.3195 22.1863 19.6128 21.093 18.3461 20.253C15.0261 18.0397 9.42615 18.0397 6.10615 20.253Z"
        fill={fill}
      />
      <path
        d="M24.4536 27.6667C23.9869 27.6667 23.5736 27.3467 23.4802 26.8667C23.3736 26.32 23.7202 25.8 24.2536 25.68C25.0936 25.5067 25.8669 25.1734 26.4669 24.7067C27.2269 24.1334 27.6402 23.4134 27.6402 22.6534C27.6402 21.8934 27.2269 21.1734 26.4802 20.6134C25.8936 20.16 25.1602 19.84 24.2936 19.64C23.7602 19.52 23.4136 18.9867 23.5336 18.44C23.6536 17.9067 24.1869 17.56 24.7336 17.68C25.8802 17.9334 26.8802 18.3867 27.6936 19.0134C28.9336 19.9467 29.6402 21.2667 29.6402 22.6534C29.6402 24.04 28.9202 25.36 27.6802 26.3067C26.8536 26.9467 25.8136 27.4134 24.6669 27.64C24.5869 27.6667 24.5202 27.6667 24.4536 27.6667Z"
        fill={fill}
      />
    </g>
  </SvgIcon>
);
