import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Картинка с подъёмным краном */
export const ConstructionIcon: FC<IconsProps> = ({ fill = 'white' }) => (
  <SvgIcon width={100} height={100} noFill noStroke>
    <path
      d="M73.2007 99.9998H20.8271C20.5208 99.9998 20.3677 99.6926 20.3677 99.539C20.3677 99.2317 20.674 99.0781 20.8271 99.0781H73.3539C73.6602 99.0781 73.8133 99.3853 73.8133 99.539C73.8133 99.6926 73.507 99.9998 73.2007 99.9998Z"
      fill={fill}
    />
    <path
      d="M65.2372 99.9998H28.0244C27.7181 99.9998 27.5649 99.6926 27.5649 99.539V87.0966C27.5649 86.7894 27.8712 86.6357 28.0244 86.6357H65.2372C65.5435 86.6357 65.6966 86.943 65.6966 87.0966V99.539C65.8498 99.6926 65.5435 99.9998 65.2372 99.9998ZM28.6369 98.9245H64.7778V87.4038H28.6369V98.9245Z"
      fill={fill}
    />
    <path
      d="M60.4901 87.4039H32.7719C32.4656 87.4039 32.3125 87.0967 32.3125 86.9431V79.2626C32.3125 78.9554 32.6188 78.8018 32.7719 78.8018H60.4901C60.7964 78.8018 60.9496 79.109 60.9496 79.2626V86.9431C61.1027 87.2503 60.7964 87.4039 60.4901 87.4039ZM33.3845 86.3286H60.0307V79.7234H33.3845V86.3286Z"
      fill={fill}
    />
    <path
      d="M41.8071 79.7235C41.5008 79.7235 41.3477 79.4163 41.3477 79.2627V40.0922C41.3477 39.785 41.6539 39.6313 41.8071 39.6313C42.1134 39.6313 42.2665 39.9386 42.2665 40.0922V79.1091C42.2665 79.4163 42.1134 79.7235 41.8071 79.7235Z"
      fill={fill}
    />
    <path
      d="M51.6079 79.7235C51.3016 79.7235 51.1484 79.4163 51.1484 79.2627V40.0922C51.1484 39.785 51.4547 39.6313 51.6079 39.6313C51.9141 39.6313 52.0673 39.9386 52.0673 40.0922V79.1091C52.2204 79.4163 51.9141 79.7235 51.6079 79.7235Z"
      fill={fill}
    />
    <path
      d="M41.8071 17.2043C41.5008 17.2043 41.3477 16.8971 41.3477 16.7435V0.460829C41.3477 0.15361 41.6539 0 41.8071 0C42.1134 0 42.2665 0.30722 42.2665 0.460829V16.5899C42.2665 16.8971 42.1134 17.2043 41.8071 17.2043Z"
      fill={fill}
    />
    <path
      d="M51.6081 19.662C51.455 19.662 51.1487 19.5084 51.1487 19.3548L45.1763 0.767963C45.0231 0.460743 45.1763 0.153524 45.4826 0.153524C45.7888 -8.6233e-05 46.0951 0.153524 46.0951 0.460743L52.0676 19.0475C52.2207 19.3548 52.0676 19.662 51.7613 19.662C51.7613 19.662 51.7613 19.662 51.6081 19.662Z"
      fill={fill}
    />
    <path
      d="M41.8069 79.5698C41.6537 79.5698 41.5006 79.5698 41.5006 79.4162C41.3475 79.2626 41.3475 78.8018 41.5006 78.6482L50.9952 69.1244L41.5006 59.6005C41.3475 59.4469 41.3475 58.9861 41.5006 58.8325C41.6537 58.6789 42.1132 58.6789 42.2663 58.8325L52.0672 68.6635C52.2204 68.8171 52.2204 69.278 52.0672 69.4316L42.2663 79.2626C42.1132 79.5698 41.96 79.5698 41.8069 79.5698Z"
      fill={fill}
    />
    <path
      d="M41.8069 60.3686C41.6537 60.3686 41.5006 60.3687 41.5006 60.215C41.3475 60.0614 41.3475 59.6006 41.5006 59.447L50.9952 49.9232L41.5006 40.3994C41.3475 40.2458 41.3475 39.7849 41.5006 39.6313C41.6537 39.4777 42.1132 39.4777 42.2663 39.6313L52.0672 49.4624C52.2204 49.616 52.2204 50.0768 52.0672 50.2304L42.2663 60.0614C42.1132 60.3686 41.96 60.3686 41.8069 60.3686Z"
      fill={fill}
    />
    <path
      d="M53.7521 40.7065H39.0508C37.5194 40.7065 36.1411 39.4776 36.1411 37.7879V19.3547C36.1411 17.665 37.5194 16.1289 39.357 16.1289H46.5546C48.3923 16.1289 50.0768 16.897 51.3019 18.1258L55.1304 22.1197C56.2024 23.195 56.6618 24.4238 56.6618 25.9599V37.7879C56.6618 39.324 55.4367 40.7065 53.7521 40.7065ZM39.357 17.2042C38.1319 17.2042 37.2131 18.1258 37.2131 19.3547V37.7879C37.2131 38.7096 37.9788 39.6312 39.0508 39.6312H53.7521C54.8241 39.6312 55.743 38.7096 55.743 37.6343V25.8063C55.743 24.7311 55.2835 23.5022 54.5178 22.7341L50.5362 18.8939C49.4642 17.8186 47.9328 17.2042 46.5546 17.2042H39.357Z"
      fill={fill}
    />
    <path
      d="M56.2023 29.0323H48.2391C45.942 29.0323 43.9512 27.189 43.9512 24.7313V16.7435C43.9512 16.4363 44.2574 16.2827 44.4106 16.2827C44.7169 16.2827 44.87 16.5899 44.87 16.7435V24.7313C44.87 26.5746 46.2483 27.9571 48.0859 27.9571H56.0492C56.3555 27.9571 56.5086 28.2643 56.5086 28.4179C56.5086 28.5715 56.5086 29.0323 56.2023 29.0323Z"
      fill={fill}
    />
    <path
      d="M17.1516 43.7787H0.459418C0.153139 43.7787 0 43.4715 0 43.3179V19.662C0 19.3548 0.306279 19.2012 0.459418 19.2012H17.1516C17.4579 19.2012 17.611 19.5084 17.611 19.662V43.1643C17.611 43.4715 17.4579 43.7787 17.1516 43.7787ZM1.07198 42.7035H16.6922V20.2764H1.07198V42.7035Z"
      fill={fill}
    />
    <path
      d="M11.6386 20.2765C11.4855 20.2765 11.3323 20.1229 11.1792 19.9693C11.0261 19.6621 11.0261 19.3549 11.3323 19.2013L41.5008 0.153634C41.8071 2.38121e-05 42.1134 2.38121e-05 42.2665 0.307244C42.4196 0.614463 42.4196 0.921683 42.1134 1.07529L11.9449 20.1229C11.7918 20.2765 11.7918 20.2765 11.6386 20.2765Z"
      fill={fill}
    />
    <path
      d="M45.6356 1.07498H41.8071C41.5008 1.07498 41.3477 0.76776 41.3477 0.61415C41.3477 0.30693 41.6539 0.15332 41.8071 0.15332H45.6356C45.9418 0.15332 46.095 0.46054 46.095 0.61415C46.095 0.76776 45.9418 1.07498 45.6356 1.07498Z"
      fill={fill}
    />
    <path
      d="M41.8071 5.99047C41.6539 5.99047 41.6539 5.99047 41.5008 5.83686C41.1945 5.68325 41.1945 5.37603 41.3477 5.06881L45.1761 0.153293C45.3293 -0.000316516 45.7887 -0.000316516 45.9418 0.153293C46.2481 0.306903 46.2481 0.614123 46.095 0.921342L42.2665 5.83686C42.1134 5.99047 41.9602 5.99047 41.8071 5.99047Z"
      fill={fill}
    />
    <path
      d="M48.6983 11.2134C48.5452 11.2134 48.5452 11.2134 48.3921 11.0597L41.5008 5.83701C41.1945 5.6834 41.1945 5.37618 41.3477 5.06896C41.5008 4.76174 41.8071 4.76174 42.1134 4.91535L49.0046 10.2917C49.1578 10.4453 49.3109 10.7525 49.0046 11.0597C49.0046 11.2134 48.8515 11.2134 48.6983 11.2134Z"
      fill={fill}
    />
    <path
      d="M41.8072 17.2039C41.654 17.2039 41.5009 17.2039 41.3478 17.0503C41.1946 16.8967 41.1946 16.4359 41.3478 16.2823L48.239 10.2915C48.3922 10.1379 48.8516 10.1379 49.0047 10.2915C49.1579 10.4451 49.1579 10.9059 49.0047 11.0595L42.1135 17.0503C42.1135 17.2039 41.9603 17.2039 41.8072 17.2039Z"
      fill={fill}
    />
    <path
      d="M36.6005 38.5559H17.1518C16.8455 38.5559 16.6924 38.2487 16.6924 38.0951C16.6924 37.7879 16.9987 37.6343 17.1518 37.6343H36.6005C36.9068 37.6343 37.0599 37.9415 37.0599 38.0951C37.2131 38.4023 36.9068 38.5559 36.6005 38.5559Z"
      fill={fill}
    />
    <path
      d="M99.5405 38.5558H56.2021C55.8958 38.5558 55.7427 38.2486 55.7427 38.095C55.7427 37.7878 56.049 37.6342 56.2021 37.6342H98.4686L90.5053 25.6526H56.2021C55.8958 25.6526 55.7427 25.3454 55.7427 25.1918C55.7427 24.8846 56.049 24.731 56.2021 24.731H90.6584C90.8116 24.731 90.9647 24.8846 91.1179 25.0382L99.8468 38.095C99.9999 38.2486 99.9999 38.4022 99.8468 38.7095C99.8468 38.4022 99.6937 38.5558 99.5405 38.5558Z"
      fill={fill}
    />
    <path
      d="M8.88227 43.7787C8.57599 43.7787 8.42285 43.4715 8.42285 43.3179V19.662C8.42285 19.3548 8.72913 19.2012 8.88227 19.2012C9.18855 19.2012 9.34169 19.5084 9.34169 19.662V43.1643C9.34169 43.4715 9.18855 43.7787 8.88227 43.7787Z"
      fill={fill}
    />
    <path
      d="M85.4519 25.4991C85.2988 25.4991 85.2988 25.4991 85.1456 25.3455L45.3294 0.921497C45.0231 0.767887 45.0231 0.460667 45.1763 0.153448C45.3294 -0.000162251 45.6357 -0.153772 45.942 0.153448L85.7582 24.5774C86.0645 24.731 86.0645 25.0382 85.9113 25.3455C85.7582 25.3455 85.6051 25.4991 85.4519 25.4991Z"
      fill={fill}
    />
    <path
      d="M79.1731 38.5558C79.0199 38.5558 78.8668 38.5558 78.8668 38.4022C78.7136 38.2486 78.5605 37.9413 78.8668 37.6341L90.3522 24.5773C90.5054 24.4237 90.8116 24.2701 91.1179 24.5773C91.2711 24.7309 91.4242 25.0381 91.1179 25.3453L79.6325 38.4022C79.4793 38.5558 79.3262 38.5558 79.1731 38.5558Z"
      fill={fill}
    />
    <path
      d="M90.8115 38.5561C90.5052 38.5561 90.3521 38.2489 90.3521 38.0953V25.0385C90.3521 24.7312 90.6583 24.5776 90.8115 24.5776C91.1177 24.5776 91.2709 24.8849 91.2709 25.0385V38.0953C91.2709 38.4025 91.1177 38.5561 90.8115 38.5561Z"
      fill={fill}
    />
    <path
      d="M79.1733 38.5561C78.867 38.5561 78.7139 38.2489 78.7139 38.0953V25.0385C78.7139 24.7312 79.0201 24.5776 79.1733 24.5776C79.4796 24.5776 79.6327 24.8849 79.6327 25.0385V38.0953C79.7858 38.4025 79.4796 38.5561 79.1733 38.5561Z"
      fill={fill}
    />
    <path
      d="M67.6877 38.5558C67.5346 38.5558 67.3814 38.5558 67.3814 38.4022C67.2283 38.2486 67.0751 37.9413 67.3814 37.6341L78.8669 24.5773C79.02 24.4237 79.3263 24.2701 79.6326 24.5773C79.7857 24.7309 79.9388 25.0381 79.6326 25.3453L68.1471 38.4022C67.994 38.5558 67.8408 38.5558 67.6877 38.5558Z"
      fill={fill}
    />
    <path
      d="M67.6874 38.5561C67.3812 38.5561 67.228 38.2489 67.228 38.0953V25.0385C67.228 24.7312 67.5343 24.5776 67.6874 24.5776C67.9937 24.5776 68.1469 24.8849 68.1469 25.0385V38.0953C68.1469 38.4025 67.9937 38.5561 67.6874 38.5561Z"
      fill={fill}
    />
    <path
      d="M56.2023 38.5558C56.0492 38.5558 55.8961 38.5558 55.8961 38.4022C55.7429 38.2486 55.5898 37.9413 55.8961 37.6341L67.3815 24.5773C67.5347 24.4237 67.8409 24.2701 68.1472 24.5773C68.3004 24.7309 68.4535 25.0381 68.1472 25.3453L56.5086 38.4022C56.3555 38.5558 56.2023 38.5558 56.2023 38.5558Z"
      fill={fill}
    />
    <path
      d="M28.6372 38.5561C28.3309 38.5561 28.1777 38.2489 28.1777 38.0953V25.0385C28.1777 24.7312 28.484 24.5776 28.6372 24.5776C28.9434 24.5776 29.0966 24.8849 29.0966 25.0385V38.0953C29.2497 38.4025 28.9434 38.5561 28.6372 38.5561Z"
      fill={fill}
    />
    <path
      d="M17.1516 38.5558C16.9984 38.5558 16.8453 38.5558 16.8453 38.4022C16.6921 38.2486 16.539 37.9413 16.8453 37.6341L28.3307 24.5773C28.4839 24.4237 28.7902 24.2701 29.0964 24.5773C29.2496 24.7309 29.4027 25.0381 29.0964 25.3453L17.611 38.4022C17.4578 38.5558 17.3047 38.5558 17.1516 38.5558Z"
      fill={fill}
    />
    <path
      d="M28.6369 38.5561C28.4838 38.5561 28.3306 38.5561 28.3306 38.4024C28.1775 38.2488 28.0244 37.9416 28.3306 37.6344L36.2939 28.5714C36.447 28.4178 36.7533 28.2642 37.0596 28.5714C37.2127 28.725 37.3659 29.0322 37.0596 29.3395L29.0963 38.4024C28.9432 38.5561 28.7901 38.5561 28.6369 38.5561Z"
      fill={fill}
    />
    <path
      d="M36.6005 25.4993H17.1518C16.8455 25.4993 16.6924 25.1921 16.6924 25.0385C16.6924 24.7312 16.9987 24.5776 17.1518 24.5776H36.6005C36.9068 24.5776 37.0599 24.8849 37.0599 25.0385C37.2131 25.1921 36.9068 25.4993 36.6005 25.4993Z"
      fill={fill}
    />
    <path
      d="M81.6235 45.7756C81.3172 45.7756 81.1641 45.4684 81.1641 45.3148V38.0951C81.1641 37.7879 81.4703 37.6343 81.6235 37.6343C81.9298 37.6343 82.0829 37.9415 82.0829 38.0951V45.3148C82.236 45.4684 81.9298 45.7756 81.6235 45.7756Z"
      fill={fill}
    />
    <path
      d="M85.4521 45.7756C85.1458 45.7756 84.9927 45.4684 84.9927 45.3148V38.0951C84.9927 37.7879 85.299 37.6343 85.4521 37.6343C85.7584 37.6343 85.9115 37.9415 85.9115 38.0951V45.3148C86.0646 45.4684 85.7584 45.7756 85.4521 45.7756Z"
      fill={fill}
    />
    <path
      d="M83.3078 60.6759C83.0015 60.6759 82.6952 60.6759 82.3889 60.5223C79.7855 60.0614 77.6416 57.7573 77.6416 54.9923C77.6416 54.6851 77.6416 54.3779 77.6416 54.0707C77.6416 53.6098 77.9479 53.149 78.4073 52.9954C78.8667 52.6882 79.3261 52.6882 79.7855 52.8418H79.9387C80.5512 53.149 81.0107 53.7634 80.8575 54.5315C80.8575 54.6851 80.8575 54.8387 80.8575 54.9923C81.0107 55.7604 81.6232 56.3748 82.2358 56.5284C82.8483 56.682 83.4609 56.5284 84.0735 56.2212C84.5329 55.7604 84.8392 55.2995 84.8392 54.5315C84.8392 53.9171 84.5329 53.3026 83.7672 52.6882C83.4609 52.381 82.8483 51.9201 82.3889 51.4593C81.7764 50.9985 81.4701 50.2304 81.4701 49.4624V48.0799H82.5421V49.4624C82.5421 49.9232 82.6952 50.384 83.1546 50.6912C83.614 51.1521 84.3797 51.6129 84.686 51.9201C85.6048 52.8418 86.0643 53.6098 86.0643 54.6851C86.0643 55.6068 85.6048 56.5284 84.8392 57.1429C84.0735 57.7573 83.1546 57.9109 82.0826 57.6037C80.8575 57.2965 80.0918 56.3748 79.7855 55.1459C79.7855 54.8387 79.7855 54.6851 79.7855 54.3779C79.7855 54.0707 79.7856 53.9171 79.4793 53.7634H79.3261C79.173 53.7634 79.0199 53.7634 79.0199 53.917C79.0199 53.917 78.8667 54.0707 78.7136 54.2243C78.7136 54.3779 78.7136 54.6851 78.7136 54.9923C78.7136 57.1429 80.3981 59.1398 82.5421 59.447C83.9203 59.7542 85.2986 59.2934 86.3705 58.3717C87.4425 57.4501 88.0551 56.2212 88.0551 54.8387C88.0551 53.9171 88.0551 52.381 85.1454 49.7696C84.686 49.3088 84.3797 48.8479 84.2266 48.3871L85.1454 47.9263C85.2986 48.3871 85.6048 48.6943 85.758 49.0015C88.9739 52.0737 88.9739 53.9171 88.9739 54.8387C88.9739 56.5284 88.2082 58.0645 86.9831 59.2934C86.0643 60.2151 84.686 60.6759 83.3078 60.6759Z"
      fill={fill}
    />
    <path
      d="M85.2986 48.8479H81.6232C81.0107 48.8479 80.5513 48.387 80.5513 47.7726V44.854H86.3706V47.7726C86.3706 48.387 85.9111 48.8479 85.2986 48.8479ZM81.7764 45.9293V47.7726H85.4517V45.9293H81.7764Z"
      fill={fill}
    />
    <path
      d="M96.3249 75.2687H70.9038C70.5975 75.2687 70.4443 74.9615 70.4443 74.8078V67.8954C70.4443 67.5882 70.7506 67.4346 70.9038 67.4346H96.3249C96.6312 67.4346 96.7843 67.7418 96.7843 67.8954V74.8078C96.9374 75.1151 96.6312 75.2687 96.3249 75.2687ZM71.5163 74.1934H95.8655V68.3562H71.5163V74.1934Z"
      fill={fill}
    />
    <path
      d="M86.5394 59.1707L85.6045 59.6968L90.3251 68.1365L91.26 67.6103L86.5394 59.1707Z"
      fill={fill}
    />
    <path
      d="M81.451 56.9772L76.0698 67.6743L77.0269 68.1587L82.408 57.4616L81.451 56.9772Z"
      fill={fill}
    />
    <path d="M77.029 67.8955H75.957V74.808H77.029V67.8955Z" fill={fill} />
    <path d="M91.2712 67.8955H90.1992V74.808H91.2712V67.8955Z" fill={fill} />
  </SvgIcon>
);
