import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Иконка с молнией */
export const FlashIcon: React.FC<IconsProps> = ({
  className,
  fill = '#2D9CDB',
}) => (
  <SvgIcon width={32} height={32} className={className} noFill noStroke>
    <g id="flash">
      <path
        d="M13.3195 30.3329C13.0528 30.3329 12.8395 30.2795 12.6795 30.2129C12.1461 30.0129 11.2395 29.3595 11.2395 27.2929V18.6929H8.11945C6.33279 18.6929 5.69279 17.8529 5.46612 17.3595C5.23945 16.8529 5.03945 15.8262 6.21279 14.4795L16.3061 3.01285C17.6661 1.46619 18.7728 1.57285 19.3061 1.77285C19.8395 1.97285 20.7461 2.62619 20.7461 4.69285V13.2929H23.8661C25.6528 13.2929 26.2928 14.1329 26.5195 14.6262C26.7461 15.1329 26.9461 16.1595 25.7728 17.5062L15.6795 28.9729C14.7328 30.0529 13.9061 30.3329 13.3195 30.3329ZM18.5728 3.65285C18.5328 3.70619 18.2528 3.83952 17.8128 4.34619L7.71945 15.8129C7.34612 16.2395 7.29279 16.5062 7.29279 16.5595C7.31945 16.5729 7.55945 16.7062 8.11945 16.7062H12.2395C12.7861 16.7062 13.2395 17.1595 13.2395 17.7062V27.3062C13.2395 27.9729 13.3595 28.2662 13.4128 28.3462C13.4528 28.2929 13.7328 28.1595 14.1728 27.6529L24.2661 16.1862C24.6395 15.7595 24.6928 15.4929 24.6928 15.4395C24.6661 15.4262 24.4261 15.2929 23.8661 15.2929H19.7461C19.1995 15.2929 18.7461 14.8395 18.7461 14.2929V4.69285C18.7595 4.02619 18.6261 3.74619 18.5728 3.65285Z"
        fill={fill}
      />
    </g>
  </SvgIcon>
);
