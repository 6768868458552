import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';
import { SvgIcon } from '../../base-components/SvgIcon.js';

/** Иконка с логотипом площадки */
export const LogoIcon: FunctionComponent<IconsProps> = ({
  className,
  fill = '#282828',
}) => (
  <SvgIcon width={150} height={88} className={className} noFill noStroke>
    <path d="M6.79688 75H0.296875V64.5H1.39687V74H6.79688V75Z" fill="black" />
    <path
      d="M16.8953 66.0004C17.8953 67.0004 18.4953 68.3004 18.4953 69.8004C18.4953 71.3004 17.9953 72.6004 16.8953 73.6004C15.7953 74.6004 14.5953 75.2004 13.0953 75.2004C11.5953 75.2004 10.2953 74.7004 9.29532 73.6004C8.29532 72.5004 7.69531 71.3004 7.69531 69.8004C7.69531 68.3004 8.19532 67.0004 9.29532 66.0004C10.3953 65.0004 11.5953 64.4004 13.0953 64.4004C14.5953 64.4004 15.8953 64.9004 16.8953 66.0004ZM10.0953 66.7004C9.29532 67.5004 8.89531 68.6004 8.89531 69.8004C8.89531 71.0004 9.29532 72.1004 10.0953 72.9004C10.8953 73.7004 11.8953 74.2004 13.0953 74.2004C14.2953 74.2004 15.2953 73.8004 16.0953 72.9004C16.8953 72.0004 17.2953 71.0004 17.2953 69.8004C17.2953 68.6004 16.8953 67.5004 16.0953 66.7004C15.2953 65.9004 14.2953 65.4004 13.0953 65.4004C11.8953 65.4004 10.8953 65.8004 10.0953 66.7004Z"
      fill="black"
    />
    <path
      d="M19.7969 64.5H27.4969V65.6H24.1969V75H23.0969V65.6H19.7969V64.5Z"
      fill="black"
    />
    <path
      d="M63.6016 7.69922V21.9992H68.6016V12.2992L144.502 6.09923V81.5992L68.6016 75.3992V59.9992H63.6016V79.9992L149.502 86.9992V0.699219L63.6016 7.69922Z"
      fill="#3B4395"
    />
    <path
      d="M0.296875 28.1992H10.9969C14.0969 28.1992 16.4969 28.8992 18.2969 30.3992C20.0969 31.8992 20.8969 33.9992 20.8969 36.7992C20.8969 39.4992 19.9969 41.5992 18.2969 42.9992C16.5969 44.3992 14.1969 45.1992 11.0969 45.1992H5.69687V55.5992H0.296875V28.1992ZM5.69687 40.6992H10.7969C12.1969 40.6992 13.3969 40.3992 14.1969 39.6992C14.9969 38.9992 15.3969 37.9992 15.3969 36.6992C15.3969 34.0992 13.7969 32.6992 10.6969 32.6992H5.79688V40.6992H5.69687Z"
      fill="black"
    />
    <path
      d="M35.7016 35.9004H38.1016V40.6004H35.4016C33.8016 40.6004 32.6016 41.2004 31.8016 42.4004C31.0016 43.6004 30.6016 45.2004 30.6016 47.4004V55.6004H25.6016V35.9004H29.9016V40.1004C31.1016 37.3004 33.0016 35.9004 35.7016 35.9004Z"
      fill="black"
    />
    <path
      d="M49.4969 35.4004C52.1969 35.4004 54.2969 36.1004 55.7969 37.4004C57.2969 38.7004 58.0969 40.8004 58.0969 43.5004V55.6004H53.7969V53.5004C52.3969 55.3004 50.3969 56.1004 47.6969 56.1004C45.4969 56.1004 43.6969 55.5004 42.3969 54.3004C40.9969 53.1004 40.2969 51.6004 40.2969 49.8004C40.2969 47.9004 40.9969 46.4004 42.3969 45.3004C43.7969 44.1004 45.6969 43.5004 47.9969 43.5004C50.0969 43.5004 51.7969 44.0004 53.0969 45.0004V43.9004C53.0969 41.1004 51.4969 39.7004 48.3969 39.7004C46.4969 39.7004 44.6969 40.2004 42.8969 41.3004H42.3969V36.8004C44.6969 35.8004 46.9969 35.4004 49.4969 35.4004ZM46.3969 51.4004C47.0969 51.9004 48.0969 52.1004 49.3969 52.1004C50.6969 52.1004 51.6969 51.9004 52.3969 51.4004C53.0969 50.9004 53.4969 50.3004 53.4969 49.5004C53.4969 48.7004 53.0969 48.1004 52.3969 47.6004C51.6969 47.1004 50.6969 46.9004 49.3969 46.9004C48.0969 46.9004 47.0969 47.1004 46.3969 47.6004C45.6969 48.1004 45.2969 48.7004 45.2969 49.5004C45.2969 50.3004 45.6969 50.9004 46.3969 51.4004Z"
      fill="black"
    />
    <path
      d="M82.1031 55.5996H75.9031L68.7031 46.1996V55.5996H63.7031V26.0996H68.7031V43.7996L75.2031 35.7996H81.3031L73.5031 44.6996L82.1031 55.5996Z"
      fill="black"
    />
    <path
      d="M98.2969 39.9004H92.5969V48.1004C92.5969 49.0004 92.8969 49.7004 93.3969 50.1004C93.8969 50.6004 94.5969 50.8004 95.4969 50.8004H98.0969V55.5004H94.5969C92.3969 55.5004 90.5969 54.9004 89.3969 53.8004C88.1969 52.7004 87.5969 51.1004 87.5969 49.0004V39.8004H83.2969V35.8004H87.5969V29.4004H92.5969V35.8004H98.2969V39.9004Z"
      fill="black"
    />
    <path
      d="M102.798 31.8992C102.198 31.3992 101.898 30.5992 101.898 29.6992C101.898 28.7992 102.198 27.9992 102.798 27.4992C103.398 26.9992 104.198 26.6992 105.098 26.6992C105.998 26.6992 106.798 26.9992 107.398 27.4992C107.998 28.0992 108.298 28.7992 108.298 29.6992C108.298 30.5992 107.998 31.3992 107.398 31.8992C106.798 32.4992 106.098 32.6992 105.098 32.6992C104.198 32.6992 103.398 32.3992 102.798 31.8992ZM102.598 35.8992H107.598V55.5992H102.598V35.8992Z"
      fill="black"
    />
    <path
      d="M112.203 54.5004V50.0004H112.703C114.603 51.4004 116.703 52.1004 119.003 52.1004C121.403 52.1004 122.503 51.5004 122.503 50.3004C122.503 49.4004 121.703 48.7004 120.203 48.1004L117.203 46.8004C114.103 45.6004 112.503 43.6004 112.503 40.9004C112.503 39.1004 113.203 37.7004 114.503 36.8004C115.903 35.8004 117.603 35.4004 119.903 35.4004C122.403 35.4004 124.403 35.9004 126.103 36.9004V41.0004H125.603C123.803 40.0004 122.103 39.4004 120.303 39.4004C118.303 39.4004 117.303 39.9004 117.303 41.0004C117.303 41.8004 118.103 42.5004 119.703 43.2004L122.603 44.4004C125.703 45.6004 127.303 47.5004 127.303 50.2004C127.303 52.3004 126.503 53.8004 124.903 54.7004C123.303 55.6004 121.403 56.1004 119.203 56.1004C116.603 56.1004 114.203 55.6004 112.203 54.5004Z"
      fill="black"
    />
  </SvgIcon>
);
