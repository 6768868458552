import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Картинка с домиком и каской рядом */
export const ServicesStatsIcon: FC<IconsProps> = ({ fill = 'white' }) => (
  <SvgIcon width={100} height={100} noFill noStroke>
    <path
      d="M97.9488 96.7108H29.2308C28.4616 96.7108 27.6924 95.942 27.6924 95.1731V90.0475C27.6924 89.2786 28.4616 88.5098 29.2308 88.5098H94.3591H94.6155L98.2052 90.8163C99.2309 91.5852 100 92.6103 100 93.8917V95.1731C99.7437 95.942 98.9744 96.7108 97.9488 96.7108ZM29.2308 89.5349C28.9744 89.5349 28.718 89.7912 28.718 90.0475V95.1731C28.718 95.4294 28.9744 95.6857 29.2308 95.6857H97.9488C98.4616 95.6857 98.718 95.4294 98.718 94.9169V93.6354C98.718 92.6103 98.2052 91.8414 97.436 91.3289L94.1027 89.2786H29.2308V89.5349Z"
      fill={fill}
    />
    <path
      d="M94.3592 89.5348C94.1028 89.5348 94.1028 89.5348 93.8464 89.2785L85.6413 73.3889C83.59 69.2884 80.2566 66.213 76.1541 64.419C75.1284 63.9065 74.1028 63.6502 72.8208 63.3939C69.7438 62.625 66.6669 62.1125 63.59 62.1125C62.0515 62.1125 60.7695 61.8562 59.231 62.1125C54.3592 62.3688 50.0002 63.1376 46.4105 64.9316C44.6156 65.7004 43.0772 66.9819 41.5387 68.2633C40.0002 69.5447 38.7182 71.0824 37.4361 72.8764C36.6669 74.1578 35.8977 75.4392 35.1284 76.9769C33.59 80.3086 32.5643 84.4091 32.0515 89.0222C32.0515 89.2785 31.7951 89.5348 31.5387 89.5348C31.2823 89.5348 31.0259 89.2785 31.0259 89.0222C31.5387 84.4091 32.5643 80.0523 34.1028 76.4643C34.872 74.9266 35.6413 73.3889 36.4105 72.1075C37.6925 70.3135 39.231 68.5196 40.7695 67.2381C42.3079 65.9567 44.1028 64.9316 45.8977 63.9065C49.7438 62.1125 54.1028 61.0873 58.9746 60.8311C60.5131 60.8311 61.7951 60.8311 63.3336 60.8311C66.4105 61.0873 69.4874 61.5999 72.5643 62.1125C73.8464 62.3688 74.872 62.8813 76.1541 63.1376C80.5131 64.9316 84.1028 68.2633 86.1541 72.6201L94.3592 88.2534C94.872 89.0222 94.872 89.2785 94.3592 89.5348Z"
      fill={fill}
    />
    <path
      d="M41.5387 83.8968C41.2823 83.8968 41.0259 83.6405 41.0259 83.3843C41.2823 80.8214 42.0515 78.5149 43.0772 76.7209C43.8464 74.9269 44.872 73.3892 46.1541 72.1078C48.2054 70.0575 51.0259 68.5198 54.1028 67.751C54.3592 67.751 54.6156 67.751 54.6156 68.0073C54.6156 68.2635 54.6156 68.5198 54.3592 68.5198C51.2823 69.0324 48.7182 70.5701 46.9233 72.6204C45.6413 73.9018 44.872 75.1832 44.1028 76.9772C43.3336 78.7712 42.5643 81.0777 42.3079 83.3843C42.0515 83.6405 41.7951 83.8968 41.5387 83.8968Z"
      fill={fill}
    />
    <path
      d="M79.2306 82.6151C78.7178 82.3588 78.7178 82.1025 78.7178 81.8462L79.487 80.3085C79.7434 80.0522 79.9998 80.0522 80.2562 80.0522C80.5126 80.3085 80.5126 80.5648 80.5126 80.8211L79.7434 82.3588C79.7434 82.6151 79.487 82.6151 79.2306 82.6151Z"
      fill={fill}
    />
    <path
      d="M76.1539 81.8462C75.6411 81.5899 75.6411 81.3336 75.6411 81.0774L77.1796 78.2582C77.436 78.002 77.6924 78.002 77.9488 78.002C78.2052 78.2582 78.2052 78.5145 78.2052 78.7708L76.6668 81.5899C76.4103 81.8462 76.4103 81.8462 76.1539 81.8462Z"
      fill={fill}
    />
    <path
      d="M73.0768 81.0773C72.8204 81.0773 72.8204 81.0773 73.0768 81.0773C72.564 80.821 72.564 80.5648 72.564 80.3085L74.8717 76.2079C74.8717 75.9517 75.3845 75.9517 75.6409 75.9517C75.8973 76.2079 75.8973 76.4642 75.8973 76.7205L73.3332 80.821L73.0768 81.0773Z"
      fill={fill}
    />
    <path
      d="M28.2054 91.5846H11.5387C11.2823 91.5846 11.0259 91.3283 11.0259 91.0721V40.0717C11.0259 39.8154 11.2823 39.5591 11.5387 39.5591C11.7951 39.5591 12.0515 39.8154 12.0515 40.0717V90.5595H28.2054C28.4618 90.5595 28.7182 90.8158 28.7182 91.0721C28.7182 91.3283 28.4618 91.5846 28.2054 91.5846Z"
      fill={fill}
    />
    <path
      d="M76.4103 64.4186C76.1539 64.4186 75.8975 64.1623 75.8975 63.906V40.0717C75.8975 39.8154 76.1539 39.5591 76.4103 39.5591C76.6667 39.5591 76.9231 39.8154 76.9231 40.0717V63.906C76.9231 64.1623 76.6667 64.4186 76.4103 64.4186Z"
      fill={fill}
    />
    <path
      d="M5.12821 45.7101C4.8718 45.7101 4.8718 45.7101 4.8718 45.4538L0 39.8156V39.5593C0 39.303 0 39.303 0.25641 39.303L43.0769 4.19221C43.5897 3.93593 44.359 3.93593 44.8718 4.19221L87.6923 39.0468L87.9487 39.303C87.9487 39.5593 87.9487 39.5593 87.9487 39.5593L83.3333 45.1976C83.0769 45.4538 82.8205 45.4538 82.5641 45.1976L44.359 14.4436C44.1026 14.1873 43.8462 14.1873 43.5897 14.4436L5.38462 45.4538C5.38462 45.7101 5.38462 45.7101 5.12821 45.7101ZM1.28205 39.5593L5.12821 44.4287L42.8205 13.6747C43.3333 13.1621 44.359 13.1621 44.8718 13.6747L82.5641 44.4287L86.6667 39.5593L44.359 4.96106C44.1026 4.70478 43.8462 4.70478 43.5897 4.96106L1.28205 39.5593Z"
      fill={fill}
    />
    <path
      d="M50.5126 41.8661H37.4357C37.1793 41.8661 36.9229 41.6098 36.9229 41.3535V28.2831C36.9229 28.0268 37.1793 27.7705 37.4357 27.7705H50.5126C50.769 27.7705 51.0254 28.0268 51.0254 28.2831V41.3535C51.0254 41.6098 50.769 41.8661 50.5126 41.8661ZM37.9485 40.841H49.9998V28.7956H37.9485V40.841Z"
      fill={fill}
    />
    <path
      d="M47.9484 39.3034H39.7433C39.4869 39.3034 39.2305 39.0471 39.2305 38.7908V30.5897C39.2305 30.3334 39.4869 30.0771 39.7433 30.0771H47.9484C48.2048 30.0771 48.4612 30.3334 48.4612 30.5897V38.7908C48.4612 39.0471 48.2048 39.3034 47.9484 39.3034ZM40.2561 38.2782H47.4356V31.1023H40.2561V38.2782Z"
      fill={fill}
    />
    <path
      d="M34.8719 77.2332H24.1027C23.8463 77.2332 23.5898 76.9769 23.5898 76.7206V47.7606C23.5898 47.5043 23.8463 47.248 24.1027 47.248H63.5898C63.8463 47.248 64.1027 47.5043 64.1027 47.7606V61.5999C64.1027 61.8562 63.8463 62.1125 63.5898 62.1125C63.3334 62.1125 63.077 61.8562 63.077 61.5999V48.2732H24.6155V75.9518H34.6155C35.3847 74.4141 35.8975 73.1327 36.6668 72.1075C37.9488 70.3136 39.4873 68.5196 41.0257 67.2382C42.5642 65.9567 44.3591 64.9316 46.1539 63.9065C50.0001 62.1125 54.3591 61.0874 59.2309 60.8311C59.4873 60.8311 59.7437 61.0874 59.7437 61.3436C59.7437 61.5999 59.4873 61.8562 59.2309 61.8562C54.3591 62.1125 50.0001 62.8813 46.4104 64.6753C44.6155 65.4442 43.077 66.7256 41.5386 68.007C40.0001 69.2884 38.7181 70.8261 37.436 72.6201C36.6668 73.9015 35.8975 75.1829 35.1283 76.7206C35.3847 76.9769 35.1283 77.2332 34.8719 77.2332Z"
      fill={fill}
    />
    <path
      d="M43.3331 77.2333C42.8203 76.9771 42.8203 76.7208 42.8203 76.4645C43.5895 74.6705 44.6152 73.1328 45.8972 71.8514C46.1536 71.5951 46.4101 71.5951 46.6665 71.8514C46.9229 72.1077 46.9229 72.364 46.6665 72.6202C45.3844 73.9017 44.6152 75.1831 43.846 76.9771C43.846 76.9771 43.5895 77.2333 43.3331 77.2333Z"
      fill={fill}
    />
    <path
      d="M37.1795 72.8764H28.4616C28.2051 72.8764 27.9487 72.6201 27.9487 72.3638V51.6049C27.9487 51.3486 28.2051 51.0923 28.4616 51.0923H41.2821C41.5385 51.0923 41.7949 51.3486 41.7949 51.6049V67.4944C41.7949 67.7507 41.5385 68.007 41.2821 68.007C41.2821 68.007 41.0257 68.007 41.0257 67.7507V52.3737H29.2308V72.1075H37.4359C37.6923 72.1075 37.9487 72.3638 37.9487 72.6201C37.6923 72.6201 37.4359 72.8764 37.1795 72.8764Z"
      fill={fill}
    />
    <path
      d="M46.4103 64.9316H46.1539C45.8975 64.9316 45.8975 64.6753 45.8975 64.419V51.6049C45.8975 51.3486 46.1539 51.0923 46.4103 51.0923H59.2308C59.4872 51.0923 59.7436 51.3486 59.7436 51.6049V61.3436C59.7436 61.5999 59.4872 61.8562 59.2308 61.8562C54.6154 62.1125 50.2564 63.1376 46.4103 64.9316ZM46.9231 52.1174V63.6502C50.2564 62.1125 54.359 61.3436 58.718 61.0873V52.3737H46.9231V52.1174Z"
      fill={fill}
    />
    <path
      d="M72.3078 41.3533H72.0514L43.8463 18.2878L15.8976 41.0971C15.6412 41.3533 15.3848 41.3533 15.1283 41.0971C14.8719 40.8408 14.8719 40.5845 15.1283 40.3282L43.3335 17.2627H43.5899C43.8463 17.2627 43.8463 17.2627 43.8463 17.519L72.0514 40.5845C72.3078 40.8408 72.3078 41.0971 72.0514 41.3533C72.5642 41.0971 72.3078 41.3533 72.3078 41.3533Z"
      fill={fill}
    />
  </SvgIcon>
);
